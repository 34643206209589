import axios from 'axios';
import { updateCartDataApi } from '../constants/apis';
import { getUserToken } from '../utils/loginUtils';
import i18n from '../i18n';

// Function to get categories
export const updateCartData = async (slugs) => {
    const token = getUserToken();
    const selectedLanguage = i18n.language || 'en';

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };

    const response = await axios.post(`${updateCartDataApi}?lang=${selectedLanguage}`, { slugs: slugs }, config);
    return response;
};