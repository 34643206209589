import React, { useState, useEffect } from 'react';
import { PartyPopper, ShoppingBag, CheckCircle } from 'lucide-react';

export const OrderSuccessModal = ({ isOpen, onClose, orderNumber = "XXXXXXXXXX" }) => {
    const [showConfetti, setShowConfetti] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setShowConfetti(true);
            // Reset confetti after animation
            const timer = setTimeout(() => setShowConfetti(false), 4000);
            return () => clearTimeout(timer);
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="relative">
                {/* Modal Content */}
                <div className="bg-white rounded-lg shadow-xl p-8 max-w-md w-full mx-4 relative overflow-hidden">
                    {/* Success Icon */}
                    <div className="flex justify-center mb-4">
                        <div className="relative">
                            <div className="absolute inset-0 animate-ping bg-green-400 rounded-full opacity-25" />
                            <CheckCircle className="h-16 w-16 text-green-500 relative z-10" />
                        </div>
                    </div>

                    {/* Confetti Effect */}
                    {showConfetti && (
                        <div className="absolute inset-0">
                            <div className="absolute -top-4 left-1/4 animate-confetti-left">
                                <PartyPopper className="h-6 w-6 text-yellow-500" />
                            </div>
                            <div className="absolute -top-4 left-1/2 animate-confetti-middle">
                                <PartyPopper className="h-6 w-6 text-blue-500" />
                            </div>
                            <div className="absolute -top-4 right-1/4 animate-confetti-right">
                                <PartyPopper className="h-6 w-6 text-pink-500" />
                            </div>
                        </div>
                    )}

                    {/* Content */}
                    <div className="text-center space-y-4">
                        <h2 className="text-2xl font-bold text-gray-800 animate-fade-in">
                            Order Placed Successfully!
                        </h2>
                        <div className="flex items-center justify-center space-x-2 text-gray-600">
                            <ShoppingBag className="h-5 w-5" />
                            <p>Order Number: {orderNumber}</p>
                        </div>
                        <p className="text-gray-600">
                            Thank you for your purchase! You will receive a confirmation email shortly.
                        </p>

                        <button
                            onClick={onClose}
                            className="mt-6 w-full bg-green-500 text-white py-2 px-4 rounded-lg 
                       hover:bg-green-600 transition-colors duration-200 font-medium"
                        >
                            Continue Shopping
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Define custom animations in your CSS/Tailwind config
const style = `
  @keyframes confetti-left {
    0% { transform: translate(0, 0) rotate(0); }
    100% { transform: translate(-100px, 100vh) rotate(-360deg); }
  }
  @keyframes confetti-middle {
    0% { transform: translate(0, 0) rotate(0); }
    100% { transform: translate(0, 100vh) rotate(360deg); }
  }
  @keyframes confetti-right {
    0% { transform: translate(0, 0) rotate(0); }
    100% { transform: translate(100px, 100vh) rotate(360deg); }
  }
  @keyframes fade-in {
    0% { opacity: 0; transform: translateY(-10px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  .animate-confetti-left { animation: confetti-left 4s ease-out forwards; }
  .animate-confetti-middle { animation: confetti-middle 4s ease-out forwards; }
  .animate-confetti-right { animation: confetti-right 4s ease-out forwards; }
  .animate-fade-in { animation: fade-in 0.5s ease-out forwards; }
`;