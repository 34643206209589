import React from 'react';

const PrivacyPolicyPage = () => {
    const policyPoints = [
        {
            title: "1. Data Collection",
            content: [
                {
                    subtitle: "Personal Information",
                    text: "We collect personal information such as name, address, contact details, and payment information for order processing."
                },
                {
                    subtitle: "Automated Data",
                    text: "Non-personal data, like browsing information, may be collected via cookies or other tracking technologies to improve site functionality."
                }
            ]
        },
        {
            title: "2. How We Use Your Information",
            content: [
                {
                    subtitle: "Order Processing and Account Management",
                    text: "Your data is used to process orders, manage accounts, and provide customer support."
                },
                {
                    subtitle: "Communication and Marketing",
                    text: "We may use your email for order updates, offers, or promotions if you have opted in. You can unsubscribe at any time."
                },
                {
                    subtitle: "Website Improvement",
                    text: "Usage data helps us improve our services and optimize the website experience."
                }
            ]
        },
        {
            title: "3. Data Sharing",
            content: [
                {
                    subtitle: "Service Providers",
                    text: "We may share your information with third-party service providers (e.g., payment gateways, logistics partners) who assist in fulfilling orders. These providers adhere to confidentiality agreements."
                },
                {
                    subtitle: "Compliance and Legal Obligations",
                    text: "We may disclose data as required by law, for fraud prevention, or to protect the rights of Fasalam Bazaar."
                }
            ]
        },
        {
            title: "4. Data Security",
            content: [
                {
                    subtitle: "",
                    text: "We implement strong data security protocols, including encryption and access controls, to protect your personal information from unauthorized access, alteration, or disclosure."
                }
            ]
        },
        {
            title: "5. User Rights",
            content: [
                {
                    subtitle: "Access and Correction",
                    text: "You may request access to or correction of your personal data."
                },
                {
                    subtitle: "Deletion of Data",
                    text: "You may request deletion of your account and data by contacting customers.fasalam@gmail.com. Certain data may be retained to comply with legal obligations."
                },
                {
                    subtitle: "Data Portability",
                    text: "We provide your personal data in a machine-readable format upon request, where feasible."
                }
            ]
        },
        {
            title: "6. Third-Party Links and Sites",
            content: [
                {
                    subtitle: "",
                    text: "Rashail Agro Bazaar may link to external sites not governed by this policy. We are not responsible for the privacy practices of these sites."
                }
            ]
        },
        {
            title: "7. Cookies",
            content: [
                {
                    subtitle: "",
                    text: "Cookies enhance user experience by remembering preferences and tracking site usage. You can disable cookies in your browser settings, but this may impact functionality."
                }
            ]
        },
        {
            title: "8. Children's Privacy",
            content: [
                {
                    subtitle: "",
                    text: "Our services are not directed to children under 13. We do not knowingly collect data from minors. If we become aware of any such data, it will be promptly deleted."
                }
            ]
        },
        {
            title: "9. Policy Updates",
            content: [
                {
                    subtitle: "",
                    text: "Fasalam Bazaar may update this Privacy Policy. Users will be notified of any significant changes, and continued use of the site indicates acceptance of the revised policy."
                }
            ]
        }
    ];

    return (
        <div className="min-h-screen bg-gray-50 mt-28 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
                <div className="text-center mb-8">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">Privacy Policy</h1>
                    <p className="text-gray-600 italic">Effective Date: [28th October 2023]</p>
                    <p className="text-xl text-gray-700 mt-4">
                        Your privacy is very important to Fasalam Bazaar. This policy outlines how we collect, use, and protect your information.
                    </p>
                </div>

                <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
                    <div className="space-y-8">
                        {policyPoints.map((section, index) => (
                            <div key={index} className="border-b border-gray-200 last:border-0 pb-6 last:pb-0">
                                <h3 className="font-bold text-gray-800 text-lg mb-4">
                                    {section.title}
                                </h3>
                                <div className="space-y-4">
                                    {section.content.map((item, idx) => (
                                        <div key={idx}>
                                            {item.subtitle && (
                                                <h4 className="font-semibold text-gray-700 mb-1">
                                                    {item.subtitle}
                                                </h4>
                                            )}
                                            <p className="text-gray-600">
                                                {item.text}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                    <span className="text-blue-800">
                        For any privacy concerns, please reach out to{' '}
                        <a
                            href="mailto:customers.fasalam@gmail.com"
                            className="font-medium underline hover:text-blue-700"
                        >
                            customers.fasalam@gmail.com
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;