import axios from 'axios';
import { geocodeApi, geocodeApiKey } from '../constants/apis';

export const decodeLocation = async ({ latitude, longitude }) => {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    const response = await axios.get(`${geocodeApi}lat=${latitude}&lon=${longitude}${geocodeApiKey}`, config);
    return response;
};