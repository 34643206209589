import axios from 'axios';
import { productListApi, productListSearchApi, productsDetailsApi, productsListSearchApi, todayOfferApi, shopVisitorsApi } from '../constants/apis';
import { getUserToken } from '../utils/loginUtils';
import i18n from '../i18n';


export const getProducts = async ({ filter }) => {
    const token = getUserToken();
    const selectedLanguage = i18n.language || 'en';
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };
    const response = await axios.get(productListApi + `?${filter}&lang=${selectedLanguage}`, config);
    return response;
};

export const getTodayProducts = async () => {
    const token = getUserToken();
    const selectedLanguage = i18n.language || 'en';
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };
    const response = await axios.get(todayOfferApi + `?lang=${selectedLanguage}`, config);
    return response;
};

export const getPopularProducts = async () => {
    const token = getUserToken();
    const selectedLanguage = i18n.language || 'en';
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };
    const response = await axios.get(productListApi + `?&lang=${selectedLanguage}`, config);
    return response;
};

export const getProductDetails = async (slug) => {
    const token = getUserToken();
    const selectedLanguage = i18n.language || 'en';
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };
    const response = await axios.get(`${productsDetailsApi}${slug}/?lang=${selectedLanguage}`, config);
    return response;
};

export const getUtmProducts = async (utmValue) => {
    const token = getUserToken();
    const selectedLanguage = i18n.language || 'en';
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };
    const response = await axios.get(`${productsListSearchApi}${utmValue.length !== 0 ? utmValue + `&lang=${selectedLanguage}` : `lang=${selectedLanguage}`}`, config);
    return response;
};

export const getSearchProducts = async (search) => {
    const token = getUserToken();
    const selectedLanguage = i18n.language || 'en';
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };
    const response = await axios.get(`${productListSearchApi}${search.length !== 0 ? search + `&lang=${selectedLanguage}` : `lang=${selectedLanguage}`}`, config);
    return response;
};

export const sendUserData = async (productId) => {
    const token = getUserToken();

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };

    const response = await axios.post(shopVisitorsApi, { user_token: token, shop_product: productId, }, config);
    return response;
};