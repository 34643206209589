import axios from "axios";
import { bazzarShopEnquiryFormApi } from "../constants/apis";

export const registerDataToApi = async (bodyData) => {

    const response = await axios.post(bazzarShopEnquiryFormApi, bodyData, {
        headers: {
            "Content-Type": "application/json",
        },
    });
    return response;
};
