import axios from 'axios';
import { searchSuggessationApi } from '../constants/apis';
import { getUserToken } from '../utils/loginUtils';
import i18n from '../i18n';

// Function to get categories
export const searchSuggessation = async (keyword) => {
    const token = getUserToken();
    const selectedLanguage = i18n.language || 'en';

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };

    const response = await axios.get(`${searchSuggessationApi}${keyword}&lang=${selectedLanguage}`, config);
    return response;
};