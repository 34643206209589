import { toast } from "react-toastify";
import { updateCartData } from "../services/cart";

export const checkProductExists = (product) => {
    let cart = JSON.parse(localStorage.getItem('cart')) || [];
    return cart.find((item) => item.id === product.id);
};

export const addToCart = (product) => {
    let cart = JSON.parse(localStorage.getItem('cart')) || [];
    const existingProduct = cart.find((item) => item.id === product.id);
    if (existingProduct) {
        existingProduct.quantity += 1;
    } else {
        cart.push({ ...product, quantity: 1 });
    }
    localStorage.setItem('cart', JSON.stringify(cart));
    toast("Product added to cart");
};

const updateCartFromApi = async () => {
    var data = JSON.parse(localStorage.getItem('cart')) || [];
    const slugs = data.map(e => e.slug);
    const response = await updateCartData(slugs);
    var newData = [];

    for (let index = 0; index < response.data.length; index++) {
        const element = response.data[index];
        var product = data.find((item) => item.id === element.id);
        const newProduct = { ...element, quantity: product.quantity };
        if (product) {
            newData.push(newProduct);
        }
    }
    localStorage.setItem('cart', JSON.stringify(newData));
}

export const getCartItems = () => {
    var data = JSON.parse(localStorage.getItem('cart')) || [];
    if (data.length !== 0) {
        updateCartFromApi();
    }
    return data;
};

export const removeFromCart = (productId) => {
    let cart = JSON.parse(localStorage.getItem('cart')) || [];
    cart = cart.filter((item) => item.id !== productId);
    localStorage.setItem('cart', JSON.stringify(cart));
    toast("Product removed from cart");
};

export const updateCartQuantity = (productId, quantity) => {
    let cart = JSON.parse(localStorage.getItem('cart')) || [];
    const product = cart.find((item) => item.id === productId);

    if (product) {
        product.quantity = quantity;
        if (quantity === 0) {
            cart = cart.filter((item) => item.id !== productId);
        }
    }
    localStorage.setItem('cart', JSON.stringify(cart));
};

export const clearCart = () => {
    localStorage.removeItem('cart');
};
