import React from 'react';
import { useState } from 'react';

const CustomLoadingCircularImage = ({ imageUrl, size, isUserImage = false, needFitCover = true }) => {
    const [isLoading, setIsLoading] = useState(true);
    const placeholderImage = 'https://rashailagro.in/logo192.png';
    const errorImage = 'https://rashailagro.in/logo192.png';

    return (
        <div
            className={`rounded-full overflow-hidden w-[${size}] h-[${size}]`}
        >
            {isLoading && (
                <img
                    src={placeholderImage}
                    alt="Loading"
                    className="object-cover w-full h-full aspect-square"
                />
            )}
            <img
                src={imageUrl}
                alt="Loaded"
                className={`object-cover w-full h-full aspect-square ${isLoading ? 'hidden' : 'block'}`}
                onLoad={() => setIsLoading(false)}
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = errorImage;
                }}
                style={{
                    display: isLoading ? 'none' : 'block'
                }}
            />
        </div>
    );
};

export default CustomLoadingCircularImage;
