import axios from 'axios';
import { categoriesListApi, catSubcatViewApi, domainGetApi } from '../constants/apis';
import { getUserToken } from '../utils/loginUtils';
import i18n from '../i18n';

// Function to get categories
export const getCategories = async () => {
    const token = getUserToken();
    const selectedLanguage = i18n.language || 'en';

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };

    // Append language to the API URL as a query parameter
    const response = await axios.get(`${categoriesListApi}?lang=${selectedLanguage}`, config);
    return response;
};

// Function to get categories and subcategories
export const getCategoriesAndSubCategories = async () => {
    const token = getUserToken();
    const selectedLanguage = i18n.language || 'en';

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };

    // Append language to the API URL as a query parameter
    const response = await axios.get(`${catSubcatViewApi}?lang=${selectedLanguage}`, config);
    return response;
};

// Function to get categories and subcategories
export const getDomainCategoryAndSubCategory = async () => {
    const token = getUserToken();
    const selectedLanguage = i18n.language || 'en';

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };

    // Append language to the API URL as a query parameter
    const response = await axios.get(`${domainGetApi}?lang=${selectedLanguage}`, config);
    return response;
};
