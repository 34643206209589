import { useEffect } from 'react';

const Modal = ({ isOpen, onClose, children }) => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        const handleOutsideClick = (event) => {
            const modal = document.getElementById('modal-content');
            // Close the modal only if the click is outside of the modal content
            if (modal && !modal.contains(event.target)) {
                onClose();
            }
        };

        // Add event listeners
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('mousedown', handleOutsideClick);

        // Cleanup event listeners on component unmount or when isOpen changes
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [onClose]); // Dependencies include onClose to avoid stale closure issues

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div id="modal-content" className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg relative">
                <button
                    className="absolute top-4 right-6 text-red-600 text-2xl hover:text-gray-800"
                    onClick={onClose}
                >
                    &times;
                </button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
