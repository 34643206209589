import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import loading from "../assets/Images/loading.gif";
import { toast } from 'react-toastify';
import logo from "../assets/Navbar/navbarshop_logo.png";
import GalleryComponent from '../components/GalleryComponent';
import { getSellers, sendUserDataForDukaan } from '../services/manufacturer';
import { getUserToken } from '../utils/loginUtils';




const SellerDetailedPage = () => {
    const { slug } = useParams();
    const [shopData, setShopData] = useState(null);
    const [customerReviews, setCustomReviews] = useState([]);


    useEffect(() => {
        const fetchShopData = async () => {
            try {
                const response = await getSellers("", "", slug);
                setShopData(response.data.results[0]);
                if (getUserToken().length !== 0) {
                    await sendUserDataForDukaan(response.data.results[0].id);
                }
            } catch (error) {
                toast("Failed to fetch shop details");
            }
        };

        fetchShopData();
    }, [slug]);

    const handleCallNow = () => {
        window.open(`tel:${shopData.contact_information}`, "_blank");

    };

    const handleWhatsapp = () => {
        window.open(`https://wa.me/${shopData.contact_information}?text=Hi, I would like to make an enquiry from your shop`, "_blank");
    };

    return (
        <div className="mt-32 bg-gray-50">
            {shopData ? (
                <div className="w-full">
                    <div className="flex flex-col lg:flex-row mx-4 md:mx-14 gap-4 my-4">
                        <div className="flex flex-col sm:flex-row gap-4 w-full">
                            <div className="flex flex-row justify-center item-center text-center rounded-lg aspect-square">
                                <img src={shopData.logo || logo} alt="Brand Logo" className='object-contain aspect-square self-center rounded-lg items-center justify-center w-full h-full max-w-64' />
                            </div>
                            <div className="flex flex-col justify-center">
                                <span className='font-semibold text-3xl'>
                                    {shopData.name}
                                </span>
                                <div className="flex flex-col items-start">
                                    <div className="flex flex-row items-center">
                                        <div className="flex flex-row bg-primary py-1 px-2 mr-4 my-3 rounded-md text-white items-center">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                className="w-4 h-4 mr-1"
                                            >
                                                <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                                            </svg>
                                            <span className="text-sm font-bold">{shopData.rating ?? "N/A"}</span>
                                        </div>
                                        {/* <span className='text-sm text-gray-500'>205 Ratings</span> */}
                                    </div>
                                    <span className="text-gray-500 text-sm">Prop: {shopData.owner_name}</span>
                                    <span className="text-gray-500 text-sm">Year of Est.: {shopData.year_of_establishment}</span>
                                    {
                                        shopData.address ?
                                            <span className="text-gray-500 text-sm">
                                                <a href={`https://maps.google.com/?q=${shopData.address.line1}, ${shopData.address.city}, ${shopData.address.state}, ${shopData.address.country}, ${shopData.address.zipcode}`} target='_blank'>
                                                    <span className="text-md text-gray-600 group-hover:text-primary">{shopData.address.city}, {shopData.address.state}, {shopData.address.country}</span>
                                                </a>
                                            </span> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                        <GalleryComponent images={shopData.images} />
                    </div>
                    <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-2 px-4 md:px-14 items-start justify-start my-4'>
                        <button
                            onClick={handleCallNow}
                            className="w-full gap-2 items-center bg-green-500 text-white py-2 px-4 text-sm fill-white rounded hover:bg-green-700 transition-colors font-semibold flex flex-row justify-center"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="h-4">
                                <path d="M511.1 383.2l-23.7 98.9c-4 16.8-19.1 29.4-36.6 29.4-256 0-464-208-464-464 0-17.5 12.5-32.6 29.4-36.6l98.9-23.7c15.9-3.8 32.3 3.7 39.5 18.8l43.8 93.7c6.7 14.3 3.7 31.3-7.5 42.4l-46.1 46.1c48.6 81 117.9 150.4 198.9 198.9l46.1-46.1c10.6-10.6 27.6-13.7 42.4-7.5l93.7 43.8c15 7.2 22.6 23.6 18.8 39.5z" />
                            </svg>
                            Call Now
                        </button>
                        <button
                            onClick={handleWhatsapp}
                            className="w-full border border-primary border-solid text-primary py-2 px-4 text-sm font-semibold rounded hover:bg-primary hover:text-white hover:fill-white fill-primary transition-colors gap-2 items-center flex flex-row justify-center"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className='h-4'>
                                <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                            </svg>
                            WhatsApp
                        </button>
                    </div>
                    {
                        (shopData.categories ?? []).length !== 0 ?
                            <div className='font-semibold text-lg mb-2 mx-4 md:mx-14'>Deals in</div>
                            : <></>
                    }
                    <div className="flex flex-wrap gap-2 mx-4 md:mx-14">
                        {(shopData.categories ?? []).map((category, categoryIndex) => (
                            <Link to={`/view/products?utm=category=${category}`}>
                                <div className="flex border-primary border rounded-lg px-4 py-1 text-primary font-semibold" key={categoryIndex}>{category}</div>
                            </Link>
                        ))}
                    </div>
                    <div className='font-semibold text-lg mb-2 mx-4 md:mx-14'>About</div>
                    <div className='text-md mb-2 mx-4 md:mx-14'>
                        <p>
                            {shopData.about}
                        </p>
                    </div>
                    {/* <SellerTabView sellerDetails={{
                        about: "ABOUT SECTION FOR THE SELLER PAGE",
                        customerReviews: customerReviews,
                        contact: {
                            phone: "+918602793619",
                            email: "contact@rashailagro.in",
                            address: "A B Road, Indore",
                        },
                    }} /> */}
                    < hr className='bg-secondary h-[1px] w-full border-0 my-4' />
                </div >
            ) : (
                <div className="h-full w-full flex justify-center">
                    <img src={loading} alt="Loading..." className='min-h-40' />
                </div>
            )
            }
        </div >
    );
};

export default SellerDetailedPage;
