import React, { useState } from 'react';
import { Route, Routes, useNavigate, Navigate, Link } from 'react-router-dom';
import { registerDataToApi } from '../../services/registration_form';
import { toast } from 'react-toastify';

function VendorRegistrationForm() {
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        company_type: '',
        pan_number: '',
        aadhar_number: '',
        gst_number: '',
        nature_of_business: '',
        address: '',
        state: '',
        city: '',
        pin_code: '',
        contact_person: '',
        whatsapp_number: '',
    });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validatePageOne = () => {
        const newErrors = {};
        if (!formData.email) {
            newErrors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Please enter a valid email address.";
        }

        if (!formData.name) {
            newErrors.name = "Company/Shop name is required.";
        }

        if (!formData.company_type) {
            newErrors.company_type = "Please select a company type.";
        }

        if (!formData.pan_number) {
            newErrors.pan_number = "Please enter PAN Number.";
        }
        if (!formData.nature_of_business) {
            newErrors.nature_of_business = "Please Select Nature.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validatePageTwo = () => {
        const newErrors = {};
        if (!formData.address) newErrors.address = "Registered address is required.";
        if (!formData.state) newErrors.state = "State is required.";
        if (!formData.city) newErrors.city = "City is required.";
        if (!formData.pin_code) {
            newErrors.pin_code = "Pincode is required.";
        } else if (!/^\d{6}$/.test(formData.pin_code)) {
            newErrors.pin_code = "Please enter a valid 6-digit pin_code.";
        }
        if (!formData.contact_person) newErrors.contact_person = "Contact person name is required.";
        if (!formData.whatsapp_number) {
            newErrors.whatsapp_number = "Contact number is required.";
        } else if (!/^\d{10}$/.test(formData.whatsapp_number)) {
            newErrors.whatsapp_number = "Please enter a valid 10-digit contact number.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNextPage = () => {
        if (validatePageOne()) {
            navigate('/vendor-registration/page-2');
        }
    };

    const handlePreviousPage = () => {
        navigate('/vendor-registration/page-1');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validatePageTwo()) {
            try {
                const response = await registerDataToApi(formData);
                if (response.status === 201) {
                    alert("Enquiry submitted successfully", "/");
                    window.location = "/";
                } else {
                    toast.error(`Something went wrong`);
                }
            } catch (error) {
                toast.error(`Error: ${error}`);
            }
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100 mt-32">
            <div className="bg-white shadow-lg rounded-lg p-6 md:p-10 w-full max-w-3xl">
                <h1 className="text-3xl font-bold text-gray-800 mb-2">
                    Fasalam Bazaar Vendor Registration
                </h1>
                <p className="text-gray-600 mb-1">Welcome to <b>Fasalam Bazaar Vendor Registration!</b></p>
                <p className="text-gray-600 mb-2">Please complete this form to register as a vendor and join our marketplace. Your information will help us verify your business and set up your profile on Fasalam Bazaar. We will list you and your products on our online platform (<Link target='_blank' to="/" className='text-primary hover:underline font-bold'>Fasalam Bazaar Website</Link> and <Link target='_blank' to="https://play.google.com/store/apps/details?id=com.rashailagro.fasalam&hl=en_IN" className='text-primary hover:underline font-bold'>Fasalam Super App</Link>) and help you scale your sales through our B2C and B2B channels.</p>
                <p className="text-gray-600 mb-2">Once the form is submitted, our team will cross-verify your details and contact you shortly to proceed with adding your products, shop, or brand to our platform. Fields marked with * are mandatory.</p>
                <p className="text-gray-600 mb-6">Thank you for partnering with us to bring quality products to our farming community!</p>
                <Routes>
                    <Route path="/" element={<Navigate to="page-1" replace />} />

                    <Route
                        path="page-1"
                        element={<PageOne formData={formData} handleChange={handleChange} handleNextPage={handleNextPage} errors={errors} />}
                    />
                    <Route
                        path="page-2"
                        element={<PageTwo formData={formData} handleChange={handleChange} handlePreviousPage={handlePreviousPage} handleSubmit={handleSubmit} errors={errors} />}
                    />
                </Routes>
            </div>
        </div>
    );
}

function PageOne({ formData, handleChange, handleNextPage, errors }) {
    return (
        <form className="space-y-6">
            <h3 className="text-xl font-semibold text-gray-700">Company/Shop Details</h3>
            <div>
                <label className="block text-gray-600 mb-1">Email <span className='text-red-700 font-bold'>*</span></label>
                <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                />
                {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
            </div>
            <div>
                <label className="block text-gray-600 mb-1">Name of Company/Shop <span className='text-red-700 font-bold'>*</span></label>
                <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                />
                {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
            </div>
            <div>
                <label className="block text-gray-600 mb-1">Type of Company <span className='text-red-700 font-bold'>*</span></label>
                <select
                    name="company_type"
                    value={formData.company_type}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                >
                    <option value="">Select</option>
                    <option value="Private Company">Private Company</option>
                    <option value="Proprietorship">Proprietorship</option>
                    <option value="Partnership">Partnership</option>
                    <option value="Other">Other</option>
                </select>
                {errors.company_type && <p className="text-red-500 text-sm mt-1">{errors.company_type}</p>}
            </div>
            <div>
                <label className="block text-gray-600 mb-1">PAN Number <span className='text-red-700 font-bold'>*</span></label>
                <input
                    type="text"
                    name="pan_number"
                    value={formData.pan_number}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                />
                {errors.pan_number && <p className="text-red-500 text-sm mt-1">{errors.pan_number}</p>}
            </div>
            <div>
                <label className="block text-gray-600 mb-1">Aadhar Number (in case of Proprietorship)</label>
                <input
                    type="text"
                    name="aadhar_number"
                    value={formData.aadhar_number}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                />
            </div>
            <div>
                <label className="block text-gray-600 mb-1">GSTIN</label>
                <input
                    type="text"
                    name="gst_number"
                    value={formData.gst_number}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                />
            </div>
            <div>
                <label className="block text-gray-600 mb-1">Nature of Business <span className='text-red-700 font-bold'>*</span></label>
                <select
                    name="nature_of_business"
                    value={formData.nature_of_business}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                >
                    <option value="">Select</option>
                    <option value="Manufacturer">Manufacturer</option>
                    <option value="Authorised Agents">Authorised Agents</option>
                    <option value="Trader (Retailer/Dealer/Distributor)">Trader (Retailer/Dealer/Distributor)</option>
                    <option value="Other">Other</option>
                </select>
                {errors.nature_of_business && <p className="text-red-500 text-sm mt-1">{errors.nature_of_business}</p>}
            </div>
            <button
                type="button"
                onClick={handleNextPage}
                className="w-full py-3 mt-4 bg-primary text-white rounded-lg hover:bg-green-700 transition duration-300"
            >
                Next
            </button>
        </form>
    );
}

function PageTwo({ formData, handleChange, handlePreviousPage, handleSubmit, errors }) {
    return (
        <form className="space-y-6">
            <h3 className="text-xl font-semibold text-gray-700">Contact Details</h3>
            <div>
                <label className="block text-gray-600 mb-1">Registered Address <span className='text-red-700 font-bold'>*</span></label>
                <textarea
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                />
                {errors.address && <p className="text-red-500 text-sm mt-1">{errors.address}</p>}
            </div>
            <div>
                <label className="block text-gray-600 mb-1">State <span className='text-red-700 font-bold'>*</span></label>
                <input
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                />
                {errors.state && <p className="text-red-500 text-sm mt-1">{errors.state}</p>}
            </div>
            <div>
                <label className="block text-gray-600 mb-1">City <span className='text-red-700 font-bold'>*</span></label>
                <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                />
                {errors.city && <p className="text-red-500 text-sm mt-1">{errors.city}</p>}
            </div>
            <div>
                <label className="block text-gray-600 mb-1">Pincode <span className='text-red-700 font-bold'>*</span></label>
                <input
                    type="text"
                    name="pin_code"
                    value={formData.pin_code}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                />
                {errors.pin_code && <p className="text-red-500 text-sm mt-1">{errors.pin_code}</p>}
            </div>
            <div>
                <label className="block text-gray-600 mb-1">Contact Person <span className='text-red-700 font-bold'>*</span></label>
                <input
                    type="text"
                    name="contact_person"
                    value={formData.contact_person}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                />
                {errors.contact_person && <p className="text-red-500 text-sm mt-1">{errors.contact_person}</p>}
            </div>
            <div>
                <label className="block text-gray-600 mb-1">Whatsapp Number <span className='text-red-700 font-bold'>*</span></label>
                <input
                    type="text"
                    name="whatsapp_number"
                    value={formData.whatsapp_number}
                    onChange={handleChange}
                    className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
                />
                {errors.whatsapp_number && <p className="text-red-500 text-sm mt-1">{errors.whatsapp_number}</p>}
            </div>
            <div className="flex justify-between">
                <button
                    type="button"
                    onClick={handlePreviousPage}
                    className="w-1/2 py-3 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition duration-300 mr-2"
                >
                    Previous
                </button>
                <button
                    type="submit"
                    onClick={handleSubmit}
                    className="w-1/2 py-3 bg-primary text-white rounded-lg hover:bg-green-700 transition duration-300 ml-2"
                >
                    Submit
                </button>
            </div>
        </form>
    );
}

export default VendorRegistrationForm;
