import { Link } from 'react-router-dom';

import visa from "../assets/Footer/Visa.png";
import applePay from "../assets/Footer/ApplePay.png";
import discover from "../assets/Footer/Discover.png";
import masterCard from "../assets/Footer/Mastercard.png";
import paypal from "../assets/Footer/PayPal.png";
import GooglePay from "../assets/Footer/GooglePay.png";
import { FaXTwitter } from "react-icons/fa6";
import { useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFooterTopProducts } from '../services/footer';
import { getPopularProducts } from '../services/product';
import VisitorCounter from './VisitorCounter';



const Footer = () => {
    const { t } = useTranslation();
    const isTab = useMediaQuery('(max-width: 966px)');
    const isMob = useMediaQuery('(max-width: 768px)');
    const [data, setData] = useState([]);
    const [popularProducts, setPopularProducts] = useState([]);
    useEffect(() => {
        const fetchRequired = async () => {
            const response = await getFooterTopProducts();
            setData(response.data.results);
            const response2 = await getPopularProducts();
            setPopularProducts(response2.data.results);
        };
        fetchRequired();
    }, []);
    return (
        <>
            <div className="bg-slate-100 mx-auto py-8">
                <div className={`grid grid-cols-2 md:grid-cols-4 gap-8`}>
                    <div className="flex flex-col justify-center item-center self-center text-center space-y-1">
                        <span className='text-primary font-bold text-xl md:text-4xl'>5+</span>
                        <span className='text-sm md:text-xl font-semibold'>{t("brands")}</span>
                    </div>
                    <div className="flex flex-col justify-center item-center self-center text-center space-y-1">
                        <span className='text-primary font-bold text-xl md:text-4xl'>5K+</span>
                        <span className='text-sm md:text-xl font-semibold'>{t("happyFamily")}</span>
                    </div>
                    <div className="flex flex-col justify-center item-center self-center text-center space-y-1">
                        <span className='text-primary font-bold text-xl md:text-4xl'>100+</span>
                        <span className='text-sm md:text-xl font-semibold'>{t("products")}</span>
                    </div>
                    <div className="flex flex-col justify-center item-center self-center text-center space-y-1">
                        <span className='text-primary font-bold text-xl md:text-4xl'>3K+</span>
                        <span className='text-sm md:text-xl font-semibold'>{t("pincodeDeivery")}</span>
                    </div>
                </div>
            </div>
            <VisitorCounter />
            <div className="bg-[#043A3A] mx-auto flex justify-center">
                <div className="w-full pt-4 pl-4">
                    <div className={`flex ${isTab ? 'flex-col' : 'flex-row'}`}>
                        <div className={`flex ${isMob ? 'flex-col' : 'flex-row'}  flex-1 ${isTab ? 'justify-between mx-4 md:mx-14' : 'justify-evenly'} mb-8`}>
                            <ul className='mb-4'>
                                <li className="text-[#9FAFA4] font-semibold text-xl mb-2">{t("help")}</li>
                                <li className="text-white my-2 text-sm"><Link className='footerlinkheading' to="/help/payments">{t("payments")}</Link></li>
                                <li className="text-white my-2 text-sm"><Link className='footerlinkheading' to="/help/shipping">{t("shipping")}</Link></li>
                                <li className="text-white my-2 text-sm"><Link className='footerlinkheading' to="/help/canellation-return">{t("cancellationReturns")}</Link></li>
                                <li className="text-white my-2 text-sm"><Link className='footerlinkheading' to="/help/faqs">{t("faq")}</Link></li>
                            </ul>
                            <ul className='mb-4'>
                                <li className="text-[#9FAFA4] font-semibold text-xl mb-2"><Link className='footerlinkheading' to="/consumer-policies">{t("consumerPolicy")}</Link></li>
                                <li className="text-white my-2 text-sm"><Link className='footerlinkheading' to="/consumer-policies/terms-of-use">{t("termsOfUse")}</Link></li>
                                <li className="text-white my-2 text-sm"><Link className='footerlinkheading' to="/consumer-policies/privacy-policies">{t("privacyPolicy")}</Link></li>
                                <li className="text-white my-2 text-sm"><Link className='footerlinkheading' to="/consumer-policies/sitemap">{t("sitemap")}</Link></li>
                                <li className={`text-white my-2 text-md text-glow ${isTab ? "font-bold" : "font-bold breathing-animation"}`}><Link className='footerlinkheading' to="/vendor-registration/" target='_blank'>{t("becomeAPartner")}</Link></li>
                            </ul>
                            <ul className='mb-4'>
                                <img src="https://rashailagro.in/static/media/Rashail%20Agro%20LOGO%20Footer%20Logo.674fcea4347e5a4fa942.png" className='max-h-11 object-contain' alt="rashail agro logo" />
                                <li className="text-white font-semibold text-xl my-4">{t("followOurSocialNetwork")}</li>
                                <div className={`flex flex-row ${isTab ? 'justify-start' : 'justify-center'}`}>
                                    <a href=" https://www.facebook.com/rashailagro/" target="_blank" alt="rashail-agro-facebook" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                        <span><i className="fa fa-facebook facebook-mobile footerlinkheading" aria-hidden="true" style={{ color: '#fff', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                                    </a>
                                    <a href="https://www.instagram.com/rashailagro/" target="_blank" alt="rashail-agro-instagram" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                        <span><i className="fa fa-instagram facebook-mobile footerlinkheading" aria-hidden="true" style={{ color: '#fff', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                                    </a>
                                    <a href="https://twitter.com/RashailAgro" target="_blank" alt="rashail-agro-twitter" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                        <FaXTwitter className='facebook-mobile twitter_footer footerlinkheading' style={{ color: '#fff', fontSize: '22px', marginRight: '20px', cursor: "pointer", marginBottom: "10px" }} />
                                    </a>
                                    <a href="https://www.youtube.com/channel/UCiyVvtJtp-MPyYWgDBHXKRw" target="_blank" alt="rashail-agro-youtube" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                        <span><i className="fa fa-youtube-play facebook-mobile footerlinkheading" aria-hidden="true" style={{ color: '#fff', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                                    </a>
                                    <a href=" https://www.linkedin.com/showcase/rashail-agro/" target="_blank" alt="rashail-agro-linkedin" rel="noopener noreferrer" onClick={() => { window.dataLayer.push({ 'event': 'button-click' }) }}>
                                        <span><i className="fa fa-linkedin facebook-mobile footerlinkheading" aria-hidden="true" style={{ color: '#fff', fontSize: '26px', marginRight: '20px', cursor: "pointer" }}></i></span>
                                    </a>
                                </div>
                            </ul>
                        </div>
                        {
                            !isTab ?
                                <div className="flex items-center mx-4">
                                    <hr className="bg-white h-36 w-0.5 border-0" />
                                </div> : <></>
                        }
                        <div className={`flex flex-col ${isTab ? 'mx-4 md:mx-14' : 'mr-14'}`}>
                            <div className="flex flex-col md:flex-row gap-4 items-start justify-end">
                                <div className="flex flex-col">
                                    <span className='flex flex-row justify-start text-primary font-bold mb-4'>{t("downloadMobileApp")}</span>
                                    <div className="flex flex-row justify-end gap-4 mb-4">
                                        <a target='_blank' href="https://apps.apple.com/in/app/fasalam/id6478108954" rel="noopener noreferrer">
                                            <img src="https://rashailagro.in/static/media/fasalam_farming_app_download_appstore.d175fbad13d0759ff08a.png" className='max-h-8' alt="appstore icon" />
                                        </a>
                                        <a target='_blank' href="https://play.google.com/store/apps/details?id=com.rashailagro.fasalam" rel="noopener noreferrer">
                                            <img src="https://rashailagro.in/static/media/fasalam_farming_app_download_google_play.9a47d4187d470330f30e.png" className='max-h-8' alt="playstore icon" />
                                        </a>
                                    </div>
                                </div>
                                <img src="https://rashailagro.in/static/media/iPhone%2015%20Pro.9dc5bfe08d7497e6c8bb.webp" alt="fasalam logo" className='max-h-24 object-contain' />
                            </div>
                            <div className={`flex ${isMob ? 'flex-col' : 'flex-row'} gap-6 justify-start mt-4`}>
                                <ul>
                                    <li className="text-[#9FAFA4] font-semibold text-xl mb-2">
                                        {t("mailUs")}
                                    </li>
                                    <li className="text-white my-2"><a className='footerlinkheading' href="mailto:rashailagro@gmail.com" alt="rashail-agro-email" style={{ color: 'white', textDecoration: "none" }}>rashailagro@gmail.com</a></li>
                                </ul>
                                <ul>
                                    <li className="text-[#9FAFA4] font-semibold text-xl mb-2">
                                        {t("ContactUs")}
                                    </li>
                                    <li className="text-white my-2"><a className='footerlinkheading' href="tel:+918349503619" alt="rashail-agro-mobilenumber" style={{ color: 'white', textDecoration: "none" }}>+91 83495 03619</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <hr className="bg-[#ffffff44] h-[0.2px] border-0" />
                    <div className={`flex ${isTab ? 'flex-col' : 'flex-row'} gap-1 py-2 px-4 md:px-14 justify-between items-center text-center`}>
                        <p className='text-white font-semibold text-sm'>
                            © Rashail Agro 2024.
                        </p>
                        <p className='text-white font-semibold text-sm'>
                            {t("100SafeSecurePayments")}
                        </p>
                        <div className="flex flex-row">
                            <img className="h-8 object-contain" src={visa} alt="visa-card-icon" />
                            <img className="h-8 object-contain" src={applePay} alt="applePay-card-icon" />
                            <img className="h-8 object-contain" src={discover} alt="discover-card-icon" />
                            <img className="h-8 object-contain" src={masterCard} alt="masterCard-card-icon" />
                            <img className="h-8 object-contain" src={paypal} alt="paypal-card-icon" />
                            <img className="h-8 object-contain" src={GooglePay} alt="GooglePay-card-icon" />
                        </div>
                    </div>
                </div>
            </div>
            <hr className="bg-[#f2a7a7] h-[2px] border-0" />
            <div className="bg-[#043a3af6] mx-auto py-4 flex flex-col px-4 md:px-14">
                <span className='text-primary md:text-xl mb-2 font-semibold'>
                    {t("allCategoriesOnBazaar")}
                </span>
                <hr className='bg-secondary h-[1px] w-full border-0' />
                {data.map((categoryObj, categoryIndex) => (
                    <div key={categoryIndex} className={`flex flex-row items-center text-xs md:text-md ${categoryObj.top_products.length === 0 ? "" : "my-1"} text-white`}>
                        {
                            categoryObj.top_products.length === 0 ? <></> :
                                <h3 className="text-md text-white font-semibold mr-2">{categoryObj.name}:</h3>
                        }

                        {/* Map over the products and display each inside a Link */}
                        <div className="text-xs md:text-md">
                            {categoryObj.top_products.map((product, productIndex) => (
                                <React.Fragment key={productIndex}>
                                    <Link
                                        to={`/view/products/${product.slug}`}
                                        className="text-green-300"
                                    >
                                        {product.name}
                                    </Link>
                                    {productIndex !== categoryObj.top_products.length - 1 && " | "}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                ))}
                <span className='text-primary md:text-xl mb-2 font-semibold'>
                    {t("popularProducts")}
                </span>
                <hr className='bg-secondary h-[1px] w-full border-0' />
                {
                    <div className="text-xs md:text-md text-green-100">
                        {popularProducts.map((product, productIndex) => (
                            <React.Fragment key={productIndex}>
                                <Link
                                    to={`/view/products/${product.slug}`}
                                    className="text-green-300"
                                >
                                    {product.product_name}
                                </Link>
                                {productIndex !== popularProducts.length - 1 && " | "}
                            </React.Fragment>
                        ))}
                    </div>
                }
            </div>
        </>
    );
};


export default Footer;
