import React, { useState } from 'react';

const OTPInput = ({ onChanged }) => {
    const [otp, setOtp] = useState(''); // Initialize OTP as a string
    const [otpError, setOtpError] = useState('');

    const handleChange = (element, index) => {
        const value = element.value;

        // Allow only digits or empty input
        if (/^\d$/.test(value) || value === '') {
            // Update OTP by replacing the character at the index
            const newOtp = otp.split('');
            newOtp[index] = value; // Update the current OTP digit
            const otpString = newOtp.join(''); // Create a new OTP string
            setOtp(otpString); // Set the updated OTP
            // Call the onChanged prop with the new OTP string
            onChanged(otpString);

            // Move to the next input if the current one is filled
            if (element.nextSibling && value) {
                element.nextSibling.focus();
            }
        } else {
            // Optional: set an error message if non-digit input is detected
            setOtpError('Please enter only digits.');
        }
    };

    const handleBackspace = (e, index) => {
        // Move back to the previous input if backspace is pressed
        if (e.key === 'Backspace' && !otp[index] && e.target.previousSibling) {
            e.target.previousSibling.focus();
        }
    };

    return (
        <div className="flex justify-center space-x-2">
            {Array.from({ length: 4 }).map((_, index) => (
                <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className="w-12 h-12 text-center text-lg border border-gray-300 focus:ring-2 focus:ring-green-500 rounded-md"
                    value={otp[index] || ''}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleBackspace(e, index)}
                />
            ))}
            {otpError && <p className="text-red-500 text-sm mt-2">{otpError}</p>}
        </div>
    );
};

export default OTPInput;
