import { Link } from 'react-router-dom';
import logo from "../assets/Navbar/navbarshop_logo.png";


const SupplierCard = ({ partner }) => {

    return (
        <div className={`bg-white rounded-lg p-2 border border-gray-200 relative min-w-[12rem] md:min-w-[22rem] shadow-lg hover:shadow-xl`}>
            <Link to={`/shop/seller/${partner.slug}/`} className={`min-w-[12rem] md:min-w-[22rem] flex flex-row items-center justify-center text-center`} state={partner} >
                <div className="relative flex flex-row items-center justify-center text-center h-40 w-60">{
                    partner.logo == null ? (
                        <img src={logo} alt="Brand Logo" className='object-contain' />)
                        : (<img
                            src={partner.logo}
                            alt={"No image available"}
                            className="h-40 object-contain rounded-lg"
                        />)}
                </div>
            </Link>

            {/* Product Details */}
            <div className="mt-4">
                <Link to={`/shop/seller/${partner.slug}/`} className={`min-w-[15rem] md:min-w-[20rem]`}>
                    <h3 className="text-lg font-bold truncate"> {/* Truncate to avoid wrapping */}
                        {partner.name}
                    </h3>
                </Link>
                <div className="flex items-center mt-2">
                    <Link to={`/shop/seller/${partner.slug}/`} className={`flex-1`}>
                        <span className="text-sm text-gray-900 flex-1 truncate">{partner.address != null ? partner.address.city : "-"}</span>
                    </Link>
                    <div className="flex flex-row gap-2">
                        <a href={`tel:${partner.contact_information}`} target='_blank' className='rounded-3xl bg-primary py-1 px-2 flex flex-row gap-1 items-center font-semibold text-white text-sm'>
                            <svg fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={11}>
                                <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                            </svg>
                            Call
                        </a>
                        <a href={`https://wa.me/${partner.contact_information}?text=Hi`} target='_blank' className='rounded-3xl bg-primary py-1 px-2 flex flex-row gap-1 items-center font-semibold text-white text-sm'>
                            WhatsApp
                        </a>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default SupplierCard;
