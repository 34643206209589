import { useSwipeable } from 'react-swipeable';
import ImageZoom from '../ImageZoom/ImageZoom';
import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import React from 'react';

function ProductImages({ productData, isTab }) {
    const [selectedImage, setSelectedImage] = useState("");
    const thumbnailRef = useRef(null);

    const handleSwipeNext = useCallback(() => {
        setSelectedImage((prevImage) => {
            const currentIndex = productData.product_images.findIndex(img => img.image === prevImage);
            const nextIndex = (currentIndex + 1) % productData.product_images.length;
            return productData.product_images[nextIndex].image;
        });
    }, [productData.product_images]);

    const handleSwipePrev = useCallback(() => {
        setSelectedImage((prevImage) => {
            const currentIndex = productData.product_images.findIndex(img => img.image === prevImage);
            const prevIndex = (currentIndex - 1 + productData.product_images.length) % productData.product_images.length;
            return productData.product_images[prevIndex].image;
        });
    }, [productData.product_images]);

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleSwipeNext,
        onSwipedRight: handleSwipePrev,
        preventScrollOnSwipe: true,
        trackMouse: true // enables mouse dragging as well
    });

    const thumbnails = useMemo(() => {
        return productData.product_images.map((e, index) => (
            <div
                key={index}
                className={`cursor-pointer inline-block h-20 w-20 items-center justify-center text-center aspect-square ${selectedImage === e.image ? 'border-2 border-primary rounded-lg' : ''}`}
                onClick={() => setSelectedImage(e.image)}
            >
                <img
                    src={e.image}
                    className="rounded-lg object-contain max-h-20 aspect-square"
                    alt={`Thumbnail ${index + 1}`}
                />
            </div>
        ));
    }, [productData.product_images, selectedImage]);

    useEffect(() => {
        if (productData.product_images.length > 0) {
            setSelectedImage(productData.product_images[0].image);
        }
    }, [productData.product_images]);

    // Scroll the thumbnails left or right by a fixed amount
    const scrollThumbnails = (direction) => {
        if (thumbnailRef.current) {
            const scrollAmount = 100; // Adjust scroll amount as needed
            thumbnailRef.current.scrollBy({
                left: direction === "right" ? scrollAmount : -scrollAmount,
                behavior: "smooth"
            });
        }
    };

    return (
        <div className="relative flex flex-col">
            <div className="flex flex-row justify-center">
                <div className="flex justify-center mb-6 items-center aspect-square object-contain max-w-96">
                    {isTab ? (
                        <div {...swipeHandlers} className="w-full h-80 object-contain">
                            <img
                                src={selectedImage}
                                className="w-full h-80 object-contain"
                                alt="Selected Product"
                            />
                        </div>
                    ) : (
                        <div className="h-80 object-contain">
                            <ImageZoom
                                src={selectedImage}
                                className="w-full h-80 object-contain"
                            />
                        </div>
                    )}
                </div>
            </div>

            {/* Thumbnail images with horizontal scrolling and left/right buttons */}
            <div className="relative flex items-center">
                {!isTab && (
                    <button
                        onClick={() => scrollThumbnails("left")}
                        className="absolute left-0 z-10 p-2 bg-black bg-opacity-5 rounded-full"
                        style={{ transform: "translateX(-50%)" }}
                    >
                        &lt;
                    </button>
                )}

                <div
                    ref={thumbnailRef}
                    className="flex overflow-x-auto whitespace-nowrap space-x-3 mb-6 h-22 px-2"
                >
                    {thumbnails}
                </div>

                {!isTab && (
                    <button
                        onClick={() => scrollThumbnails("right")}
                        className="absolute right-0 z-10 p-2 bg-black bg-opacity-5 rounded-full"
                        style={{ transform: "translateX(50%)" }}
                    >
                        &gt;
                    </button>
                )}
            </div>
        </div>
    );
}

export default React.memo(ProductImages);
