export const getUserToken = () => {
    let user = localStorage.getItem('token') || "";
    return user;
};

export const saveUserToken = (token) => {
    localStorage.setItem('token', token);
};

export const clearUser = () => {
    localStorage.removeItem('token');
};
