import axios from "axios";
import { bazzarCategoriesWithTopProductsApi } from "../constants/apis";
import { getUserToken } from "../utils/loginUtils";
import i18n from "../i18n";

// Function to get slides
export const getFooterTopProducts = async () => {
    const token = getUserToken();
    const selectedLanguage = i18n.language || 'en';

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };
    const response = await axios.get(`${bazzarCategoriesWithTopProductsApi}?lang=${selectedLanguage}`, config);
    return response;
};
