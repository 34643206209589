import React from "react";
import { CgCloseO } from "react-icons/cg";
import Modal from "react-modal";

const getCustomStyles = (width = "30%", isSidebar = false) => ({
    content: isSidebar ?
        {
            top: "0%",
            right: "0%",
            left: "auto",
            bottom: "auto",
            width: width,
            maxWidth: "100%",
            zIndex: 1050,
            padding: "20px",
            height: "100%",
        } : {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: width,
            maxWidth: "100%",
            zIndex: 1050,
            padding: "20px",
        },
    overlay: {
        zIndex: 1040,
        backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
});

Modal.setAppElement("#root");

const ShowModal = ({
    isOpen,
    onRequestClose,
    contentLabel,
    componentToPass,
    modalWidth,
    isSidebar
}) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={getCustomStyles(modalWidth, isSidebar)}
            contentLabel={contentLabel}
        >
            <div className="w-full">
                <div className="flex flex-row justify-end">
                    <CgCloseO
                        color="red"
                        onClick={onRequestClose}
                        className="cursor-pointer"
                    />
                </div>
                {componentToPass}
            </div>
        </Modal>
    );
};

export default ShowModal;