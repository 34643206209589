import React from 'react';

const ConsumerPolicyPage = () => {
    const policyPoints = [
        {
            title: "Quality Commitment",
            content: "While Rashail Agro Bazaar ensures high standards in vendor selection, product quality may vary by brand. Please review product details carefully."
        },
        {
            title: "Pricing Transparency",
            content: "Prices are clearly listed without hidden charges, ensuring transparency."
        },
        {
            title: "Privacy and Security",
            content: "We prioritize the confidentiality of your personal information, ensuring compliance with privacy regulations."
        },
        {
            title: "Consumer Assistance",
            content: "For support, queries, and grievances, our customer care is available via email."
        }
    ];

    return (
        <div className="min-h-screen bg-gray-50 mt-28 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
                <div className="text-center mb-8">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">Consumer Policy</h1>
                    <h2 className="text-2xl font-semibold text-gray-700 italic">
                        Your Consumer Rights and Responsibilities
                    </h2>
                </div>

                <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
                    <div className="mb-4">
                        <h3 className="text-xl font-semibold text-gray-800">
                            We are committed to fair trade practices and customer satisfaction. Here's what you can expect from us:
                        </h3>
                    </div>
                    <div className="space-y-6">
                        {policyPoints.map((point, index) => (
                            <div key={index} className="border-b border-gray-200 last:border-0 pb-4 last:pb-0">
                                <h3 className="font-semibold text-gray-800 mb-2">
                                    {point.title}
                                </h3>
                                <p className="text-gray-600">
                                    {point.content}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                    <span className="text-blue-800">
                        If you feel any of your consumer rights are unmet, contact{' '}
                        <a
                            href="mailto:customers.fasalam@gmail.com"
                            className="font-medium underline hover:text-blue-700"
                        >
                            customers.fasalam@gmail.com
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ConsumerPolicyPage;