import React from 'react';

const ShippingPage = () => {
    const shippingPoints = [
        {
            title: "Processing and Dispatch",
            content: "Orders are processed within 1-2 business days, followed by dispatch."
        },
        {
            title: "Delivery Timeframe",
            content: "Standard delivery typically takes 3-7 business days, depending on location and courier availability."
        },
        {
            title: "Shipping Costs",
            content: "Free shipping is available on orders above ₹500. Orders below ₹500 may incur nominal shipping fees."
        },
        {
            title: "Order Tracking",
            content: "A tracking number is provided upon dispatch to allow you to monitor the shipment's status."
        },
        {
            title: "Liability for Delays",
            content: "Delays due to logistics partners or unforeseen circumstances are beyond our control; however, our team will assist you in tracking your order and resolving any issues."
        },
        {
            title: "Remote Area Serviceability",
            content: "Delivery may be limited in certain remote areas. Please check availability during checkout."
        }
    ];

    return (
        <div className="min-h-screen bg-gray-50 mt-28 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
                <div className="text-center mb-8">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">Shipping</h1>
                    <h2 className="text-2xl font-semibold text-gray-700 italic">
                        Reliable and Fast Shipping Services
                    </h2>
                </div>

                <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
                    <div className="mb-4">
                        <h3 className="text-xl font-semibold text-gray-800">
                            Our shipping services ensure your orders reach you on time and in excellent condition:
                        </h3>
                    </div>
                    <div className="space-y-6">
                        {shippingPoints.map((point, index) => (
                            <div key={index} className="border-b border-gray-200 last:border-0 pb-4 last:pb-0">
                                <h3 className="font-semibold text-gray-800 mb-2">
                                    {point.title}
                                </h3>
                                <p className="text-gray-600">
                                    {point.content}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                    <span className="text-blue-800">
                        For additional shipping information, contact{' '}
                        <a
                            href="mailto:customers.fasalam@gmail.com"
                            className="font-medium underline hover:text-blue-700"
                        >
                            customers.fasalam@gmail.com
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ShippingPage;