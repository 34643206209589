import React from 'react';

const TermsOfUsePage = () => {
    const termsPoints = [
        {
            title: "User Eligibility",
            content: "To use this site, you must be 18 years or older or have parental or guardian consent. By creating an account, you represent that the information you provide is accurate, complete, and up-to-date."
        },
        {
            title: "Account Responsibility",
            content: "Users are responsible for maintaining the confidentiality of their account credentials. Fasalam Bazaar is not liable for any loss or damage arising from unauthorized access due to failure to secure your credentials."
        },
        {
            title: "Platform as an Intermediary",
            content: "Fasalam Bazaar serves as an online marketplace connecting buyers and sellers. We do not guarantee product quality or service standards from third-party vendors and assume no responsibility for product quality or warranties, which are managed by the individual brands or vendors."
        },
        {
            title: "Product Information and Pricing",
            content: "We strive for accuracy in product listings, descriptions, and pricing. However, errors may occur, and Fasalam Bazaar reserves the right to correct such inaccuracies. If a product's price is inaccurately listed, we may cancel the order and notify you."
        },
        {
            title: "Return, Replacement, and Warranty",
            content: "Return and replacement policies vary by product and brand. Before purchasing, contact customer care for specific policies. Warranty and guarantee claims must be directed to the associated brand or vendor. Fasalam Bazaar can assist but assumes no responsibility for these claims."
        },
        {
            title: "Prohibited Activities",
            content: "Users must not engage in activities such as: Misrepresentation, fraud, or any illegal activity on the platform, attempting to access unauthorized areas of the site, misusing or abusing the returns and cancellation policies."
        },
        {
            title: "Intellectual Property",
            content: "All content, graphics, and other materials on Fasalam Bazaar are protected by intellectual property rights. Users may not reproduce, modify, or distribute this content without permission."
        },
        {
            title: "Order Cancellations",
            content: "Fasalam Bazaar reserves the right to cancel orders in specific cases, such as stock unavailability, pricing inaccuracies, or suspicion of fraudulent activity. In such cases, refunds are processed within 5-10 business days."
        },
        {
            title: "Indemnification",
            content: "By using this site, you agree to indemnify Fasalam Bazaar, its affiliates, and partners from any claim or liability arising from your use, misuse, or non-compliance with these terms."
        },
        {
            title: "Limitation of Liability",
            content: "Fasalam Bazaar is not liable for any indirect, incidental, or consequential damages arising from the use of our platform. Our maximum liability is limited to the value of the goods purchased."
        },
        {
            title: "Modifications to Terms",
            content: "Fasalam Bazaar may update these terms at any time. Users will be notified of significant changes, and continued use of the site implies acceptance of the modified terms."
        }
    ];

    return (
        <div className="min-h-screen bg-gray-50 mt-28 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
                <div className="text-center mb-8">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">Terms of Use</h1>
                    <p className="text-gray-600 italic">Effective Date: [Insert Date]</p>
                </div>

                <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
                    <div className="mb-4">
                        <h3 className="text-xl font-semibold text-gray-800">
                            Welcome to Fasalam Bazaar! By using our platform, you agree to the following terms and conditions. Please read them carefully.
                        </h3>
                    </div>
                    <div className="space-y-6">
                        {termsPoints.map((point, index) => (
                            <div key={index} className="border-b border-gray-200 last:border-0 pb-4 last:pb-0">
                                <h3 className="font-semibold text-gray-800 mb-2">
                                    {point.title}
                                </h3>
                                <p className="text-gray-600">
                                    {point.content}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                    <span className="text-blue-800">
                        For further questions or concerns, please reach out to{' '}
                        <a
                            href="mailto:customers.fasalam@gmail.com"
                            className="font-medium underline hover:text-blue-700"
                        >
                            customers.fasalam@gmail.com
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default TermsOfUsePage;