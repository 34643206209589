import axios from "axios";
import { loginApi, signupApi, verifyApi } from "../constants/apis";
var mobile = "";
export const sendOtp = async ({ phoneNumber }) => {
    mobile = String(phoneNumber);
    try {
        var bodyData = {
            "mobile_number": String(phoneNumber),
        };
        const response = await axios.post(loginApi, bodyData, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error("Error sending OTP:", error);
        throw error;
    }
};

export const sendSignupOtp = async ({ phoneNumber, name }) => {
    mobile = String(phoneNumber);
    try {
        var bodyData = {
            "mobile_number": String(phoneNumber),
            "name": name,
        };
        const response = await axios.post(signupApi, bodyData, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error("Error sending OTP:", error);
        throw error;
    }
};

export const resendOtp = async () => {
    try {
        var bodyData = {
            "mobile_number": mobile,
        };
        const response = await axios.post(loginApi, bodyData, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error("Error sending OTP:", error);
        throw error;
    }
};

export const verifyOtp = async ({ otp }) => {
    try {
        var bodyData = {
            mobile_number: mobile,
            otp: otp,
        };
        const response = await axios.post(verifyApi, bodyData, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response;
    } catch (error) {
        console.error("Error sending OTP:", error);
        throw error;
    }
};
