import { useEffect, useState } from 'react';
import Modal from './Modal';
import { sendAddressDataToApi } from '../services/user_detail_api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getCurrentPosition } from "geolocation";
import { decodeLocation } from "../services/geocoding.js";
import locationIcon from "../assets/location.png";

const AddressComponent = ({ userAddresses, selectedAddressId, handleAddressSelect, handleAddAddress, showAddressAddPop }) => {
    const { t } = useTranslation();
    const [showAddressForm, setShowAddressForm] = useState(false);
    const [newAddress, setNewAddress] = useState({
        line1: '',
        line2: '',
        landmark: '',
        city: '',
        district: '',
        alternate_phone: '',
        state: '',
        country: 'India',
        zipcode: '',
    });

    const [errors, setErrors] = useState({}); // To track validation errors

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAddress({ ...newAddress, [name]: value });
    };

    const getUserLocation = () => {
        getCurrentPosition(function (err, position) {
            if (err) throw console.log("Error in fetching location", err);
            decodeUserLocation(position.coords.latitude, position.coords.longitude);
        });
    };

    const decodeUserLocation = async (latitude, longitude) => {
        const response = await decodeLocation({ latitude: latitude, longitude: longitude, });
        const address = response.data.address;
        setNewAddress({
            line1: address.amenity || "",
            line2: address.road || "",
            landmark: '',
            city: address.city || "",
            district: address.district || "",
            alternate_phone: address.alternate_phone || '',
            state: address.state || "",
            country: address.country || "",
            zipcode: address.postcode || "",
        });
    };

    // Validate form fields
    const validateForm = () => {
        const newErrors = {};

        if (!newAddress.line1.trim()) {
            newErrors.line1 = 'Line 1 is required';
        }
        if (!newAddress.city.trim()) {
            newErrors.city = 'City is required';
        }
        if (!newAddress.district.trim()) {
            newErrors.district = 'District is required';
        }
        if (!newAddress.state.trim()) {
            newErrors.state = 'State is required';
        }
        if (!newAddress.zipcode.trim()) {
            newErrors.zipcode = 'Zipcode is required';
        } else if (!/^\d{6}$/.test(newAddress.zipcode)) {  // Ensure it's a 6-digit number
            newErrors.zipcode = 'Zipcode must be 6 digits';
        }

        setErrors(newErrors);

        // If there are no errors, return true
        return Object.keys(newErrors).length === 0;
    };

    const handleFormSubmit = async () => {
        if (validateForm()) {
            const bodyData = {
                city: newAddress.city,
                country: newAddress.country,
                district: newAddress.district,
                landmark: newAddress.landmark,
                line1: newAddress.line1,
                line2: newAddress.line2,
                state: newAddress.state,
                alternate_phone: newAddress.alternate_phone,
                user: userAddresses?.userdetails?.id,
                zipcode: newAddress.zipcode,
            };
            const response = await sendAddressDataToApi(bodyData);

            if (response.status === 201) {
                handleAddAddress(newAddress);
                setShowAddressForm(false);
                setNewAddress({
                    line1: '',
                    line2: '',
                    landmark: '',
                    city: '',
                    district: '',
                    state: '',
                    country: 'India',
                    zipcode: '',
                    alternate_phone: '',
                });
                setErrors({});
            } else {
                toast("Something went wrong while adding address");
            }
        }
    };

    useEffect(() => {
        if (userAddresses.addresses.length > 0) {
            handleAddressSelect(userAddresses.addresses[0].id);

        }
    }, []);

    return (
        <div className={`flex flex-col relative rounded-lg bg-white shadow-lg px-8 py-4 border ${userAddresses.addresses.length > 0 ? "max-h-[25.5rem]" : ""} items-start w-full lg:w-1/3`}>
            <p className="py-2 font-bold text-lg">{t("SelectDeliveryAddress")}</p>
            <div className="flex flex-col w-full overflow-y-auto">
                {userAddresses && userAddresses.addresses && userAddresses.addresses.length > 0 ? (
                    userAddresses.addresses.map((address) => (
                        <div
                            key={address.id}
                            className="flex items-start w-full bg-gray-100 p-4 rounded-md border mb-2 cursor-pointer"
                            onClick={() => handleAddressSelect(address.id)}
                        >
                            <input
                                type="checkbox"
                                name="selectedAddress"
                                checked={selectedAddressId === address.id}
                                onChange={() => handleAddressSelect(address.id)}
                                className="mr-4 mt-1 cursor-pointer"
                            />
                            <div className="flex flex-col w-full">
                                <p className="font-semibold">{address.city}, {address.district}</p>
                                <p className="text-sm text-gray-600">
                                    {address.line1 ? `${address.line1}, ` : ''}{address.line2 ? `${address.line2}, ` : ''}
                                    {address.landmark ? `${address.landmark}, ` : ''}{address.zipcode}, {address.state}, {address.country}
                                    {address.alternate_phone ?
                                        (
                                            <>
                                                <br />
                                                {address.alternate_phone}
                                            </>
                                        ) : (<></>)
                                    }
                                </p>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="flex flex-col">
                        <div className="flex flex-col gap-2">
                            <button
                                className="text-primary font-bold flex flex-row gap-1 items-center"
                                onClick={getUserLocation}
                            >
                                <img src={locationIcon} alt="locationIcon" className='max-w-4 fill-primary' />
                                {t("useMyLocation")}
                            </button>
                            <div>
                                <input
                                    type="text"
                                    name="line1"
                                    placeholder="Address 1*"
                                    value={newAddress.line1}
                                    onChange={handleInputChange}
                                    className="p-2 border rounded w-full"
                                />
                                {errors.line1 && <p className="text-red-500 text-sm">{errors.line1}</p>}
                            </div>

                            <input
                                type="text"
                                name="line2"
                                placeholder="Address 2 (Optional)"
                                value={newAddress.line2}
                                onChange={handleInputChange}
                                className="p-2 border rounded w-full"
                            />

                            <div>
                                <input
                                    type="text"
                                    name="landmark"
                                    placeholder="Landmark (Optional)"
                                    value={newAddress.landmark}
                                    onChange={handleInputChange}
                                    className="p-2 border rounded w-full"
                                />
                            </div>

                            <div>
                                <input
                                    type="text"
                                    name="alternate_phone"
                                    placeholder="Alternate Phone (Optional)"
                                    value={newAddress.alternate_phone}
                                    onChange={handleInputChange}
                                    className="p-2 border rounded w-full"
                                />
                            </div>

                            <div>
                                <input
                                    type="text"
                                    name="city"
                                    placeholder="City*"
                                    value={newAddress.city}
                                    onChange={handleInputChange}
                                    className="p-2 border rounded w-full"
                                />
                                {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}
                            </div>

                            <div>
                                <input
                                    type="text"
                                    name="district"
                                    placeholder="District*"
                                    value={newAddress.district}
                                    onChange={handleInputChange}
                                    className="p-2 border rounded w-full"
                                />
                                {errors.district && <p className="text-red-500 text-sm">{errors.district}</p>}
                            </div>

                            <div>
                                <input
                                    type="text"
                                    name="state"
                                    placeholder="State*"
                                    value={newAddress.state}
                                    onChange={handleInputChange}
                                    className="p-2 border rounded w-full"
                                />
                                {errors.state && <p className="text-red-500 text-sm">{errors.state}</p>}
                            </div>

                            <div>
                                <input
                                    type="text"
                                    name="zipcode"
                                    placeholder="Zipcode*"
                                    value={newAddress.zipcode}
                                    onChange={handleInputChange}
                                    className="p-2 border rounded w-full"
                                />
                                {errors.zipcode && <p className="text-red-500 text-sm">{errors.zipcode}</p>}
                            </div>
                        </div>

                        <button
                            className="bg-green-500 text-white py-2 px-4 rounded mt-4"
                            onClick={handleFormSubmit}
                        >
                            {t("saveAddress")}
                        </button>
                    </div>
                )}
            </div>
            {
                userAddresses.addresses.length > 0 ?
                    <>
                        <hr className="my-2 border-black w-full border-1 border-dotted" />
                        <button
                            className="bg-primary text-white py-2 px-4 rounded mt-4 w-full font-bold"
                            onClick={() => setShowAddressForm(!showAddressForm)}
                        >
                            {showAddressForm ? 'Cancel' : t("addNewAddress")}
                        </button>
                    </>
                    : <></>}

            {/* Modal for adding new address */}
            <Modal isOpen={showAddressForm} onClose={() => setShowAddressForm(false)}>
                <div className="flex flex-row gap-2 items-center">
                    <p className="font-bold mb-2 text-lg">{t("addNewAddress")}</p>
                </div>
                <div className="flex flex-col gap-2">
                    <button
                        className="text-primary font-bold flex flex-row gap-1 items-center"
                        onClick={getUserLocation}
                    >
                        <img src={locationIcon} alt="locationIcon" className='max-w-4 fill-primary' />
                        {t("useMyLocation")}
                    </button>
                    <div>
                        <input
                            type="text"
                            name="line1"
                            placeholder="Address 1*"
                            value={newAddress.line1}
                            onChange={handleInputChange}
                            className="p-2 border rounded w-full"
                        />
                        {errors.line1 && <p className="text-red-500 text-sm">{errors.line1}</p>}
                    </div>

                    <input
                        type="text"
                        name="line2"
                        placeholder="Address 2 (Optional)"
                        value={newAddress.line2}
                        onChange={handleInputChange}
                        className="p-2 border rounded w-full"
                    />

                    <div>
                        <input
                            type="text"
                            name="landmark"
                            placeholder="Landmark (Optional)"
                            value={newAddress.landmark}
                            onChange={handleInputChange}
                            className="p-2 border rounded w-full"
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            name="alternate_phone"
                            placeholder="Alternate Phone (Optional)"
                            value={newAddress.alternate_phone}
                            onChange={handleInputChange}
                            className="p-2 border rounded w-full"
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            name="city"
                            placeholder="City*"
                            value={newAddress.city}
                            onChange={handleInputChange}
                            className="p-2 border rounded w-full"
                        />
                        {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}
                    </div>

                    <div>
                        <input
                            type="text"
                            name="district"
                            placeholder="District*"
                            value={newAddress.district}
                            onChange={handleInputChange}
                            className="p-2 border rounded w-full"
                        />
                        {errors.district && <p className="text-red-500 text-sm">{errors.district}</p>}
                    </div>

                    <div>
                        <input
                            type="text"
                            name="state"
                            placeholder="State*"
                            value={newAddress.state}
                            onChange={handleInputChange}
                            className="p-2 border rounded w-full"
                        />
                        {errors.state && <p className="text-red-500 text-sm">{errors.state}</p>}
                    </div>

                    <div>
                        <input
                            type="text"
                            name="zipcode"
                            placeholder="Zipcode*"
                            value={newAddress.zipcode}
                            onChange={handleInputChange}
                            className="p-2 border rounded w-full"
                        />
                        {errors.zipcode && <p className="text-red-500 text-sm">{errors.zipcode}</p>}
                    </div>
                </div>

                <button
                    className="bg-green-500 text-white py-2 px-4 rounded mt-4"
                    onClick={handleFormSubmit}
                >
                    {t("saveAddress")}
                </button>
            </Modal>

            {/* Overlay div */}
            {showAddressAddPop && (
                <div className="absolute top-0 left-0 w-full h-full bg-primary bg-opacity-50 z-10 rounded-lg p-8 flex justify-center items-center">
                    <button
                        className="bg-white text-primary py-2 px-4 rounded mt-4 w-full font-bold border-primary border-2"
                        onClick={() => setShowAddressForm(!showAddressForm)}
                    >
                        {showAddressForm ? 'Cancel' : t('addNewAddress')}
                    </button>
                </div>
            )}
        </div>

    );
};

export default AddressComponent;
