import React from 'react';

const PaymentsPage = () => {
    const paymentPoints = [
        {
            title: "Accepted Payment Methods",
            content: "Use credit/debit cards, UPI, net banking, and wallet payments."
        },
        {
            title: "Secure Transactions",
            content: "All payments are encrypted and protected to ensure your financial information remains secure."
        },
        {
            title: "Order Confirmation",
            content: "You'll receive an email and SMS confirming your order once payment is successful."
        },
        {
            title: "Failed Transactions",
            content: "If a payment fails, the amount will be refunded to your original payment method within 3-7 business days."
        },
        {
            title: "No Liability for Third-Party Failures",
            content: "Rashail Agro Bazaar is not liable for payment gateway issues, delays, or failures caused by third-party payment providers."
        }
    ];

    return (
        <div className="mt-28 min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
                <div className="text-center mb-8">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">Payments</h1>
                    <h2 className="text-2xl font-semibold text-gray-700 italic">
                        Secure & Flexible Payment Options
                    </h2>
                </div>

                <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
                    <div className="mb-4">
                        <h3 className="text-xl font-semibold text-gray-800">
                            We prioritise a secure and flexible payment experience for all our customers:
                        </h3>
                    </div>
                    <div className="space-y-6">
                        {paymentPoints.map((point, index) => (
                            <div key={index} className="border-b border-gray-200 last:border-0 pb-4 last:pb-0">
                                <h3 className="font-semibold text-gray-800 mb-2">
                                    {point.title}
                                </h3>
                                <p className="text-gray-600">
                                    {point.content}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 flex items-center">
                    <span className="text-blue-800">
                        For payment inquiries, contact our support team at{' '}
                        <a
                            href="mailto:customers.fasalam@gmail.com"
                            className="font-medium underline hover:text-blue-700"
                        >
                            customers.fasalam@gmail.com
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PaymentsPage;