import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

const ProductDetailsTabView = ({ productDetails }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('TECHNICAL_DETAILS');

    const tabs = [
        { name: t('technicalDetails'), key: 'TECHNICAL_DETAILS' },
        { name: t('benefits'), key: 'BENEFITS' },
        { name: t('useCases'), key: 'USECASE' },
        { name: t('inBox'), key: 'IN_BOX' },
        { name: t('additionalDetails'), key: 'ADDITIONAL_DETAILS' },
    ];

    return (
        <div className="space-y-5">
            <div className="overflow-hidden rounded-xl border border-gray-100 bg-gray-50 p-1">
                <ul className="flex items-center text-sm font-medium overflow-x-auto whitespace-nowrap">
                    {tabs.map((tab) => (
                        <li className="flex-1 my-1 px-4 mx-0" key={tab.key}>
                            <p
                                onClick={(e) => {
                                    e.preventDefault();
                                    setActiveTab(tab.key);
                                }}
                                className={`relative flex items-center cursor-pointer justify-center gap-2 border-primary px-3 py-2 ${activeTab === tab.key
                                    ? 'text-primary border-b-2'
                                    : 'text-gray-500 hover:bg-white hover:text-gray-700 hover:border-b-2'
                                    }`}
                            >
                                {tab.name}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="px-4 md:px-4">
                {activeTab === 'TECHNICAL_DETAILS' && (
                    <ul className="list-disc list-inside lg:mx-96">
                        {(productDetails.TECHNICAL_DETAILS || []).map((item, index) => (
                            <li key={index} className='flex flex-row w-full'>
                                <div className="text-xs md:text-md flex w-full px-4 py-2 border border-black font-bold items-center">{item.key}</div>
                                <div className="text-xs md:text-md flex w-full px-4 py-2 border border-black">{item.value}</div>
                            </li>
                        ))}
                    </ul>
                )}
                {activeTab === 'BENEFITS' && (
                    <ul className="list-disc list-inside">
                        {(productDetails.BENEFITS || []).map((item, index) => (
                            <li key={index}>{item.value}</li>
                        ))}
                    </ul>
                )}
                {activeTab === 'USECASE' && (
                    <div>
                        <ul className="list-disc list-inside mb-4">
                            {(productDetails.USECASE || []).map((item, index) => (
                                item.link ? (
                                    <li key={index} className="flex items-center gap-2 pb-4 justify-center w-full">
                                        <ReactPlayer url={item.link} />
                                    </li>
                                ) : (
                                    <li key={index}>
                                        <span>{item.value || 'No description available.'}</span>
                                    </li>
                                )
                            ))}
                        </ul>
                    </div>
                )}

                {activeTab === 'IN_BOX' && (
                    <ul className="list-disc list-inside">
                        {(productDetails.IN_BOX || []).map((item, index) => (
                            <li key={index}>{item.value}</li>
                        ))}
                    </ul>
                )}
                {activeTab === 'ADDITIONAL_DETAILS' && (
                    <ul className="list-disc list-inside">
                        {(productDetails.ADDITIONAL_DETAILS || []).map((item, index) => (
                            <li key={index}>{item.value}</li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default ProductDetailsTabView;
