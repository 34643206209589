import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { searchSuggessation } from '../services/search_suggessation';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import { useTranslation } from 'react-i18next';
import { EraserIcon } from 'lucide-react';
import { Close, Remove } from '@mui/icons-material';

const SearchBar = () => {
    const { t } = useTranslation();
    const navigate = useNavigate(); // Initialize useNavigate for navigation
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState({
        products: [],
        categories: [],
        subcategories: [],
        shops: [],
        domains: [],
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Function to handle API call and fetch suggestions
    const handleInputChange = async (event) => {
        const input = event.target.value;
        setQuery(input);

        if (input.length > 0) {
            setLoading(true);
            try {
                const response = await searchSuggessation(input); // Call your API
                const data = response.data;

                setSuggestions({
                    products: data.products || [],
                    categories: data.categories || [],
                    subcategories: data.subcategories || [],
                    shops: data.shops || [],
                    domains: data.domains || [],
                });

            } catch (err) {
                console.error("Error fetching suggestions:", err);
                setError("Failed to fetch suggestions");
            } finally {
                setLoading(false);
            }
        } else {
            setSuggestions({
                products: [],
                categories: [],
                subcategories: [],
                shops: [],
                domains: [],
            });
        }
    };

    // Function to handle suggestion click
    const handleSuggestionClick = (suggestion) => {
        setQuery(suggestion);
        setSuggestions({
            products: [],
            categories: [],
            subcategories: [],
            shops: [],
            domains: [],
        });
    };

    // Function to handle the "Enter" key press
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSuggestionClick(query);
            navigate(`/view/products?search=${query}`);
        }
    };

    // Function to handle suggestion click
    const handleClearField = () => {
        setQuery("");
        setSuggestions({
            products: [],
            categories: [],
            subcategories: [],
            shops: [],
            domains: [],
        });
    };

    return (
        <div className="relative flex flex-col">
            {/* Search Input */}
            <div className="relative flex items-center">
                <input
                    type="text"
                    value={query}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    placeholder={t("search")}
                    className="border-2 border-gray-300 bg-gray-100 text-sm rounded-full pl-4 pr-10 py-2 w-full focus:outline-none focus:ring-2 focus:ring-green-500"
                />
                {
                    query.length === 0 ?
                        <SearchIcon className="absolute right-3 top-2 text-gray-500" /> :
                        <Close className="absolute right-3 top-2 text-gray-500 cursor-pointer" onClick={handleClearField} />
                }
            </div>

            {/* Loading and error handling */}
            {loading && <div className="absolute top-full w-full bg-white border border-gray-300 px-4 py-8 rounded-lg shadow-md mt-2 z-10">Loading...</div>}
            {error && <div className="absolute top-full w-full bg-white border border-gray-300 px-4 py-8 rounded-lg shadow-md mt-2 z-10">{error}</div>}

            {/* Suggestions Box */}
            {(suggestions.products.length > 0 || suggestions.categories.length > 0 || suggestions.subcategories.length > 0 || suggestions.shops.length > 0) && (
                <div className="absolute top-full left-0 w-full bg-white border border-gray-300 rounded-lg shadow-md mt-2 z-10">
                    {/* Products */}
                    {suggestions.products.length > 0 && (
                        <div className="p-2">
                            <h3 className="text-sm font-semibold mb-2">{t("products")}</h3>
                            <ul>
                                {suggestions.products.map((product, index) => (
                                    <Link to={`/view/products?utm=name=${product.product_name}`}
                                        onClick={() => handleSuggestionClick(product.product_name)}
                                    >
                                        <li
                                            key={index}
                                            className="px-4 py-2 hover:bg-green-100 cursor-pointer truncate"
                                        >
                                            {product.product_name}
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                        </div>
                    )}
                    {suggestions.domains.length > 0 && (
                        <div className="p-2">
                            <h3 className="text-sm font-semibold mb-2">{t("domains")}</h3>
                            <ul>
                                {suggestions.domains.map((domain, index) => (
                                    <Link to={`/view/products?utm=domain=${domain.name}`}
                                        onClick={() => handleSuggestionClick(domain.name)}
                                        className='w-full'
                                    >
                                        <li
                                            key={index}
                                            className="px-4 py-2 hover:bg-green-100 cursor-pointer truncate"
                                        >
                                            {domain.name}
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                        </div>
                    )}

                    {/* Categories */}
                    {suggestions.categories.length > 0 && (
                        <div className="p-2">
                            <h3 className="text-sm font-semibold mb-2">{t("categories")}</h3>
                            <ul>
                                {suggestions.categories.map((category, index) => (
                                    <Link to={`/view/products?utm=category=${category.name}`}
                                        onClick={() => handleSuggestionClick(category.name)}
                                        className='w-full'
                                    >
                                        <li
                                            key={index}
                                            className="px-4 py-2 hover:bg-green-100 cursor-pointer"
                                        >
                                            {category.name}
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                        </div>
                    )}

                    {/* Subcategories */}
                    {suggestions.subcategories.length > 0 && (
                        <div className="p-2">
                            <h3 className="text-sm font-semibold mb-2">{t("subCategories")}</h3>
                            <ul>
                                {suggestions.subcategories.map((subcategory, index) => (
                                    <Link to={`/view/products?utm=subcategory=${subcategory.name}`}
                                        onClick={() => handleSuggestionClick(subcategory.name)}
                                    >
                                        <li
                                            key={index}
                                            className="px-4 py-2 hover:bg-green-100 cursor-pointer w-full"
                                        >
                                            {subcategory.name}
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                        </div>
                    )}

                    {/* Shops */}
                    {suggestions.shops.length > 0 && (
                        <div className="p-2">
                            <h3 className="text-sm font-semibold text-gray-600 mb-1">{t("shops")}</h3>
                            <ul>
                                {suggestions.shops.map((shop, index) => (
                                    <li
                                        key={index}
                                        className="px-4 py-2 hover:bg-green-100 cursor-pointer"
                                    >
                                        <Link to={`/shop/partners/${shop.name}/`}
                                            onClick={() => handleSuggestionClick(shop.name)}
                                            className='w-full'
                                        >
                                            {shop.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchBar;
