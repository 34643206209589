import React from 'react';
import { useTranslation } from 'react-i18next';

// Component to display individual star rating bars
const RatingBar = ({ stars, percentage, count }) => (
    <div className="flex items-center w-full">
        <p className="font-medium text-lg text-black mr-2">{stars}</p>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 44 44"
            fill="none"
        >
            <g clipPath="url(#clip0_13624_2608)">
                <path
                    d="M21.1033 2.9166C21.4701 2.17335 22.5299 2.17335 22.8967 2.9166L28.233 13.729C28.3786 14.0241 28.6602 14.2287 28.9859 14.276L40.9181 16.0099C41.7383 16.1291 42.0658 17.137 41.4723 17.7156L32.8381 26.1318C32.6024 26.3616 32.4949 26.6926 32.5505 27.017L34.5888 38.9009C34.7289 39.7178 33.8714 40.3408 33.1378 39.9551L22.4653 34.3443C22.174 34.1911 21.826 34.1911 21.5347 34.3443L10.8622 39.9551C10.1286 40.3408 9.27114 39.7178 9.41125 38.9009L11.4495 27.017C11.5051 26.6926 11.3976 26.3616 11.1619 26.1318L2.52771 17.7156C1.93419 17.137 2.2617 16.1291 3.08192 16.0099L15.0141 14.276C15.3398 14.2287 15.6214 14.0241 15.767 13.729L21.1033 2.9166Z"
                    fill="#FBBF24"
                />
            </g>
            <defs>
                <clipPath id="clip0_13624_2608">
                    <rect width="44" height="44" fill="white" />
                </clipPath>
            </defs>
        </svg>
        <p className="h-2 w-full sm:min-w-[278px] rounded-3xl bg-amber-50 ml-5 mr-3">
            <span className={`h-full w-[${percentage}%] rounded-3xl bg-primary flex`}></span>
        </p>
        <p className="font-medium text-lg text-black mr-0.5">{count}</p>
    </div>
);

// Component for individual customer review
const ReviewItem = ({ name, image, rating, reviewText }) => (
    <div className="flex items-center gap-3 mb-4">
        <img src={image} alt={`${name} image`} className="w-14 h-14 rounded-full object-cover" />
        <div className="flex flex-col">
            <h6 className="font-semibold text-lg leading-8 text-primary">{name}</h6>
            <div className="flex items-center gap-1">
                {Array.from({ length: rating }).map((_, index) => (
                    <svg
                        key={index}
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 44 44"
                        fill="none"
                    >
                        <g clipPath="url(#clip0_13624_2608)">
                            <path
                                d="M21.1033 2.9166C21.4701 2.17335 22.5299 2.17335 22.8967 2.9166L28.233 13.729C28.3786 14.0241 28.6602 14.2287 28.9859 14.276L40.9181 16.0099C41.7383 16.1291 42.0658 17.137 41.4723 17.7156L32.8381 26.1318C32.6024 26.3616 32.4949 26.6926 32.5505 27.017L34.5888 38.9009C34.7289 39.7178 33.8714 40.3408 33.1378 39.9551L22.4653 34.3443C22.174 34.1911 21.826 34.1911 21.5347 34.3443L10.8622 39.9551C10.1286 40.3408 9.27114 39.7178 9.41125 38.9009L11.4495 27.017C11.5051 26.6926 11.3976 26.3616 11.1619 26.1318L2.52771 17.7156C1.93419 17.137 2.2617 16.1291 3.08192 16.0099L15.0141 14.276C15.3398 14.2287 15.6214 14.0241 15.767 13.729L21.1033 2.9166Z"
                                fill="#FBBF24"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_13624_2608">
                                <rect width="44" height="44" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                ))}
            </div>
            <p>{reviewText}</p>
        </div>
    </div>
);

export const CustomerReviews = ({ reviews }) => {
    const { t } = useTranslation();

    // Calculate total ratings and average rating
    const totalRatings = reviews.length;

    const sumOfRatings = reviews.reduce((sum, review) => sum + review.rating, 0);
    const averageRating = (totalRatings > 0) ? (sumOfRatings / totalRatings).toFixed(1) : 0;

    // Calculate percentage for each star rating
    const ratingCounts = Array(5).fill(0);
    reviews.forEach(review => {
        ratingCounts[review.rating - 1]++;
    });

    const ratingPercentages = ratingCounts.map(count => ((count / totalRatings) * 100).toFixed(2));

    return (
        <section className="relative">
            {/* <div className="w-full max-w-7xl lg-6 mx-auto"> */}
            {/* <div className="w-full"> */}
            {/* <h2 className="font-manrope font-bold text-4xl text-black mb-8 text-center">{t("ourCustomerReviews")}</h2> */}

            {/* Ratings overview */}
            {/* <div className="grid grid-cols-1 xl:grid-cols-2 gap-14 border-b border-gray-100 max-xl:max-w-2xl max-xl:mx-auto">
                        <div className="flex flex-col gap-y-4 w-full">
                            {ratingCounts.map((count, index) => (
                                <RatingBar
                                    key={index}
                                    stars={5 - index} // 5 stars to 1 star
                                    percentage={ratingPercentages[index]}
                                    count={count}
                                />
                            ))}
                        </div> */}

            {/* Average Rating */}
            {/* <div className="p-8 bg-amber-50 rounded-3xl flex items-center justify-center flex-col">
                            <h2 className="font-manrope font-bold text-5xl text-primary mb-6">{averageRating}</h2>
                            <div className="flex items-center justify-center gap-2 sm:gap-6 mb-4">
                                {Array.from({ length: Math.round(averageRating) }).map((_, index) => (
                                    <svg
                                        key={index}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="25"
                                        viewBox="0 0 44 44"
                                        fill="none"
                                    >
                                        <g clipPath="url(#clip0_13624_2608)">
                                            <path
                                                d="M21.1033 2.9166C21.4701 2.17335 22.5299 2.17335 22.8967 2.9166L28.233 13.729C28.3786 14.0241 28.6602 14.2287 28.9859 14.276L40.9181 16.0099C41.7383 16.1291 42.0658 17.137 41.4723 17.7156L32.8381 26.1318C32.6024 26.3616 32.4949 26.6926 32.5505 27.017L34.5888 38.9009C34.7289 39.7178 33.8714 40.3408 33.1378 39.9551L22.4653 34.3443C22.174 34.1911 21.826 34.1911 21.5347 34.3443L10.8622 39.9551C10.1286 40.3408 9.27114 39.7178 9.41125 38.9009L11.4495 27.017C11.5051 26.6926 11.3976 26.3616 11.1619 26.1318L2.52771 17.7156C1.93419 17.137 2.2617 16.1291 3.08192 16.0099L15.0141 14.276C15.3398 14.2287 15.6214 14.0241 15.767 13.729L21.1033 2.9166Z"
                                                fill="#FBBF24"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_13624_2608">
                                                <rect width="44" height="44" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                ))}
                            </div>
                            <p className="font-medium text-xl leading-8 text-gray-900 text-center">{totalRatings} Ratings</p>
                        </div> */}
            {/* </div> */}

            {/* Reviews list */}
            {/* <div className="pt-8 max-xl:max-w-2xl max-xl:mx-auto">
                        {reviews.map((review, index) => (
                            <ReviewItem
                                key={index}
                                name={review.name}
                                image={review.image}
                                rating={review.rating}
                                reviewText={review.comment}
                            />
                        ))}
                    </div> */}
            {/* </div>
            </div> */}
        </section>
    );
};
