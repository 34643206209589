import React from 'react';

const FAQPage = () => {
    const faqPoints = [
        {
            title: "Order Tracking",
            question: "How can I track my order?",
            answer: "Once shipped, a tracking number is provided for you to monitor the delivery progress."
        },
        {
            title: "Changing Delivery Address",
            question: "Can I change my delivery address after placing an order?",
            answer: "Unfortunately, address changes are not possible after order confirmation, so please verify your details before checkout."
        },
        {
            title: "Damaged Product Receipt",
            question: "What should I do if I receive a damaged or incorrect item?",
            answer: "If your product is damaged or incorrect, contact us within 48 hours with photos. We will help arrange a replacement."
        },
        {
            title: "Return Policies",
            question: "Are all products eligible for returns?",
            answer: "Return eligibility varies by product and vendor. Refer to the product page or contact customer care for clarification."
        }
    ];

    return (
        <div className="min-h-screen bg-gray-50 mt-28 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto">
                <div className="text-center mb-8">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">FAQ</h1>
                    <h2 className="text-2xl font-semibold text-gray-700 italic">
                        Frequently Asked Questions
                    </h2>
                </div>

                <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
                    <div className="mb-4">
                        <h3 className="text-xl font-semibold text-gray-800">
                            Find answers to some common queries:
                        </h3>
                    </div>
                    <div className="space-y-6">
                        {faqPoints.map((point, index) => (
                            <div key={index} className="border-b border-gray-200 last:border-0 pb-4 last:pb-0">
                                <h3 className="font-semibold text-gray-800 mb-2">
                                    {point.title}
                                </h3>
                                <div className="space-y-2">
                                    <p className="text-gray-700 font-medium">
                                        {point.question}
                                    </p>
                                    <p className="text-gray-600">
                                        {point.answer}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
                    <span className="text-blue-800">
                        If you have more questions, reach out to us at{' '}
                        <a
                            href="mailto:customers.fasalam@gmail.com"
                            className="font-medium underline hover:text-blue-700"
                        >
                            customers.fasalam@gmail.com
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default FAQPage;