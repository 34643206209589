import React, { useEffect, useState } from 'react';
import signup_shop from '../../assets/Navbar/signupimg.png';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import OTPInput from './OtpInput';
import { toast } from 'react-toastify';
import { resendOtp, verifyOtp } from '../../services/login';
import { saveUserToken } from '../../utils/loginUtils';
import { useTranslation } from 'react-i18next';

const Otplogin = ({ handleModalClose }) => {
    const { t } = useTranslation();
    const isMob = useMediaQuery('(max-width: 768px)');
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(60);
    const [isResending, setIsResending] = useState(false);

    useEffect(() => {
        const countdown = setInterval(() => {
            if (timer > 0 && !isResending) {
                setTimer(timer - 1);
            } else if (timer === 0 && !isResending) {
                clearInterval(countdown);
            }
        }, 1000);

        return () => clearInterval(countdown);
    }, [timer, isResending]);

    // Custom Caesar Cipher encryption
    function encrypt(text, key) {
        let encryptedText = "";
        key = parseInt(key) % 26; // Ensure key is within the alphabet range

        for (let i = 0; i < text.length; i++) {
            let char = text[i];

            if (char.match(/[a-z]/i)) {  // Check if character is a letter
                let charCode = text.charCodeAt(i);
                let base = charCode >= 65 && charCode <= 90 ? 65 : 97; // ASCII code for 'A' or 'a'
                encryptedText += String.fromCharCode((charCode - base + key) % 26 + base);
            } else {
                encryptedText += char;  // Non-alphabetic characters are not encrypted
            }
        }

        return encryptedText;
    }

    // Custom Caesar Cipher decryption
    function decrypt(ciphertext, key) {
        let decryptedText = "";
        key = parseInt(key) % 26; // Ensure key is within the alphabet range

        for (let i = 0; i < ciphertext.length; i++) {
            let char = ciphertext[i];

            if (char.match(/[a-z]/i)) {  // Check if character is a letter
                let charCode = ciphertext.charCodeAt(i);
                let base = charCode >= 65 && charCode <= 90 ? 65 : 97; // ASCII code for 'A' or 'a'
                decryptedText += String.fromCharCode((charCode - base - key + 26) % 26 + base);
            } else {
                decryptedText += char;  // Non-alphabetic characters are not decrypted
            }
        }

        return decryptedText;
    }

    const handleVerify = async () => {
        if (otp.length === 4) {
            try {
                const response = await verifyOtp({ otp: otp });
                var data = response.data;
                if (data.status === 200) {
                    const apiKey = process.env.REACT_APP_API_KEY;
                    var decryptedToken = decrypt(data.token, apiKey);
                    saveUserToken(decryptedToken);
                    handleModalClose();
                }
                toast(data.message);
            } catch (error) {
                toast(error);
            }
        }
    };

    const handleResendOTP = async () => {
        setIsResending(true);
        setTimer(60);
        await resendOtp();
        setTimeout(() => {
            setIsResending(false);
        }, 1000);
    };

    const handleOtpChange = (newOtp) => {
        setOtp(newOtp);
    };

    return (
        <div className="flex flex-col md:flex-row justify-center text-center max-w-screen-sm">
            {
                isMob ? <button
                    className="absolute top-4 right-4 p-2 rounded-full bg-primary"
                    onClick={handleModalClose}
                >
                    <CloseIcon style={{ color: '#ffffff' }} />
                </button> : <></>
            }
            <div className="md:w-1/2 bg-primary flex flex-col justify-center p-10">
                <h2 className="text-xl text-left font-bold mb-2">{t("otp")}</h2>
                <p className="text-left text-white mb-4 text-md">
                    {t("hey")}<br />{t("pleaseEnterTheOtpSentOnYourNumber")}
                </p>
                <img src={signup_shop} alt="Fasalam farming app" className="max-w-60 h-auto mb-4 hidden md:flex" />
            </div>

            <div className="md:w-1/2 flex flex-col justify-center p-8 relative bg-white">
                {
                    isMob ? <></> : <button
                        className="absolute top-4 right-4 p-2 rounded-full bg-primary"
                        onClick={handleModalClose}
                    >
                        <CloseIcon style={{ color: '#ffffff' }} />
                    </button>
                }
                <div className="col-md-7 text-center justify-content-center d-flex flex-column pt-4 pb-4">
                    <h2 className="text-xl text-left font-bold mb-2">{t("otp")}</h2>
                    <form className='mb-2'>
                        <OTPInput onChanged={handleOtpChange} />
                    </form>
                    <button className='mt-4 w-full py-2 rounded-full text-white bg-green-600 hover:bg-green-700 text-sm cursor-pointer mb-4' onClick={handleVerify}>
                        {t("verify")}
                    </button>
                    <p className='text-xs font-semibold'>{t("haveNotReceivedCodeYet")} <span className='signupparacolor_shop' onClick={handleResendOTP} style={{ cursor: "pointer" }}>
                        {isResending ? t("resending") : timer > 0 ? `00:${timer < 10 ? '0' + timer : timer}` : t("resendOtp")}
                    </span></p>
                </div>
            </div>
        </div>
    );
};

export default Otplogin;
