import axios from 'axios';
import { bazzarShippingAddressApi, bazzarUserShippingAddressApi } from '../constants/apis';
import { getUserToken } from '../utils/loginUtils';

// Function to get categories
export const userDetailsData = async () => {
    const token = getUserToken();

    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };

    const response = await axios.get(bazzarUserShippingAddressApi, config);
    return response;
};

export const sendAddressDataToApi = async (bodyData) => {
    const token = getUserToken();

    const response = await axios.post(bazzarShippingAddressApi, bodyData, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
        },
    });
    return response;
};
