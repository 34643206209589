import { TextField, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import signup_shop from '../../assets/Navbar/signupimg.png';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { sendSignupOtp } from "../../services/login";

const Signup = ({ handleModalClose, switchToOtp, switchToLogin, prefilledNumber }) => {
    const { t } = useTranslation();
    const isMob = useMediaQuery('(max-width: 768px)');
    const [mobileNumber, setMobileNumber] = useState('');
    const [name, setName] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(true);
    const [error, setError] = useState('');
    const [nameError, setNameError] = useState('');

    const handleMobileNumberChange = (event) => {
        const { value } = event.target;
        // Ensure only numeric characters are entered
        if (/^\d*$/.test(value) || value === '') {
            setMobileNumber(value);
        }
    };

    const handleNameChange = (event) => {
        const { value } = event.target;
        setName(value);
    };

    useEffect(() => {
        if (prefilledNumber) {
            setMobileNumber(prefilledNumber);
        }
    }, []);

    const handleRequestOTP = async () => {
        // Validate mobile number format
        if (mobileNumber.trim().length !== 10 || !/^\d{10}$/.test(mobileNumber.trim()) || name.trim().length === 0) {
            if (mobileNumber.trim().length !== 10 || !/^\d{10}$/.test(mobileNumber.trim())) {
                setError('Please enter a valid 10-digit mobile number.');
            } else {
                setError('');
            }
            if (name.trim().length === 0) {
                setNameError('Please Provide a name');
            }
        } else {
            try {
                const mob = mobileNumber;
                const response = await sendSignupOtp({ phoneNumber: mob, name: name, });
                var data = response.data;
                toast(data.message);
                console.log(data.status);
                console.log(data.status === 200);

                if (data.status === 200) {
                    setError('');
                    switchToOtp();
                }
            } catch (error) {
                toast.error(error);
            }
        }
    };

    const handleCheckboxChange = (event) => {
        setTermsAccepted(event.target.checked);
    };

    return (
        <div className="flex flex-col md:flex-row justify-center text-center max-w-screen-sm">
            {/* Close Icon if mobile view */}
            {
                isMob ? <button
                    className="absolute top-4 right-4 p-2 rounded-full bg-primary"
                    onClick={handleModalClose}
                >
                    <CloseIcon style={{ color: '#ffffff' }} />
                </button> : <></>
            }
            {/* Left side */}
            <div className="md:w-1/2 bg-primary flex flex-col justify-center p-10">
                <h2 className="text-xl text-left font-bold mb-2">{t("signup")}</h2>
                <p className="text-left text-white mb-4 text-md">
                    {t("createAccount")}<br />{t("letKeepYourShoppingSeamless")}
                </p>
                <img src={signup_shop} alt="Fasalam farming app" className="max-w-40 h-auto mb-4 hidden md:flex" />
            </div>

            {/* Right side */}
            <div className="md:w-1/2 flex flex-col justify-center p-8 relative bg-white">
                {/* Close Icon if web view */}
                {
                    isMob ? <></> : <button
                        className="absolute top-4 right-4 p-2 rounded-full bg-primary"
                        onClick={handleModalClose}
                    >
                        <CloseIcon style={{ color: '#ffffff' }} />
                    </button>
                }

                {/* Input for Mobile Number */}
                <div className="mt-4 text-left mb-1">
                    <TextField
                        id="standard-basic"
                        label={t("enterYourDigitMobileNumber")}
                        variant="standard"
                        type="tel"
                        fullWidth
                        value={mobileNumber}
                        onChange={handleMobileNumberChange}
                    />
                    {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                    <TextField
                        id="standard-basic"
                        label={t("name")}
                        variant="standard"
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                    />
                    {nameError && <p className="text-red-500 text-sm mt-2">{nameError}</p>}
                </div>

                {/* Terms and Conditions */}
                <div className="flex items-start text-sm flex-row justify-start gap-2 text-start">
                    <input
                        type="checkbox"
                        id="termsCheckbox"
                        name="termsCheckbox"
                        value="terms"
                        checked={termsAccepted}
                        onChange={handleCheckboxChange}
                        className="mt-1"
                    />
                    <label htmlFor="termsCheckbox" className="text-gray-600">
                        {t("continuingByAcceptingRashailAgro")}{' '}
                        <span className="text-green-600 font-semibold cursor-pointer">{t("termsOfUse")}</span>{' '}
                        {t("and")}{' '}
                        <span className="text-green-600 font-semibold cursor-pointer">{t("privacyPolicy")}</span>
                    </label>
                </div>

                {!termsAccepted && (
                    <p className="text-red-500 text-xs mt-2 text-left">
                        {t("pleaseAcceptTheTermsOfUseAndPrivacyPolicyToProceed")}
                    </p>
                )}

                {/* Request OTP Button */}
                <button
                    className={`mt-2 w-full py-2 rounded-full text-white text-sm ${termsAccepted
                        ? 'bg-green-600 hover:bg-green-700 cursor-pointer'
                        : 'bg-gray-300 cursor-not-allowed'
                        }`}
                    onClick={handleRequestOTP}
                    disabled={!termsAccepted}
                >
                    {t("requestOtp")}
                </button>

                {/* Signup Link */}
                <div className="mt-auto">
                    <p className="mt-4 text-primary text-xs font-semibold cursor-pointer" onClick={switchToLogin}>
                        {t("loginAlreadyHaveAnAccount")}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Signup
