import React, { useEffect, useState, useRef } from 'react';
import { useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { getSearchProducts, getUtmProducts } from '../services/product';
import { addToCart } from '../utils/cartUtils';
import ProductCard from '../components/ProductCard';
import { callPagination } from '../services/pagination';
import noProductsImage from "../assets/noProducts.png";
import LoadingSpinner from '../components/LoadingSpinner';

const ViewAllProducts = () => {
    const isTab = useMediaQuery('(max-width: 966px)');
    const [products, setProducts] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const listEndRef = useRef(); // Element to observe at the end of the list
    const [loading, setLoading] = useState(false);
    const [showLoading, setShowLoading] = useState(true);

    const location = useLocation();
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const utmValue = urlParams.get('utm') ? decodeURIComponent(urlParams.get('utm')) : '';
    const searchValue = urlParams.get('search') ? decodeURIComponent(urlParams.get('search')) : '';
    let utmValueToDisplay = urlParams.get('utm') ? decodeURIComponent(urlParams.get('utm')) : '';
    if (utmValueToDisplay.includes("=")) {
        utmValueToDisplay = utmValueToDisplay.split("=")[1];
    }

    // Fetch data based on UTM or search value
    useEffect(() => {
        const fetchRequired = async () => {
            setLoading(true);
            setShowLoading(true);
            try {
                let prods;
                if (searchValue) {
                    prods = await getSearchProducts(`?search=${searchValue}`);
                } else if (utmValue) {
                    prods = await getUtmProducts(utmValue);
                } else {
                    prods = await getUtmProducts("");
                }
                setProducts(prods.data.results);
                setNextPage(prods.data.next);
                setShowLoading(false);

            } catch (error) {
                console.error("Error fetching products:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchRequired();
    }, [utmValue, searchValue]);

    // Infinite scrolling logic with Intersection Observer
    useEffect(() => {
        if (!listEndRef.current || !nextPage) return;

        const observer = new IntersectionObserver(
            async (entries) => {
                const [entry] = entries;
                if (entry.isIntersecting && nextPage && !loading) {
                    console.log("Intersection observed, calling more pages...");
                    await callMorePages();
                }
            },
            { rootMargin: '100px', threshold: 1.0 }
        );

        observer.observe(listEndRef.current);

        return () => {
            if (listEndRef.current) {
                observer.unobserve(listEndRef.current);
            }
        };
    }, [nextPage, loading]);

    const callMorePages = async () => {
        if (!nextPage || loading) return;

        setLoading(true);
        try {
            const nextPageData = await callPagination(nextPage);
            setProducts((prevProducts) => [...prevProducts, ...nextPageData.data.results]);
            setNextPage(nextPageData.data.next);
        } catch (error) {
            console.error("Error fetching more pages:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddToCart = (product) => {
        addToCart(product);
    };

    return (
        showLoading ? <LoadingSpinner /> :
            <div className="mt-32">
                {utmValue.length !== 0 && (
                    <div className="py-14 bg-gradient-to-r from-primary to-green-400 text-white flex justify-center">
                        <h1 className="text-2xl md:text-4xl font-extrabold mb-4">{utmValueToDisplay}</h1>
                    </div>
                )}
                {products.length !== 0 ? (
                    <div className={`grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-4 ${isTab ? "py-8" : "py-8 px-14"} mx-2 md:mx-14`}>
                        {products.map((product) => (
                            <ProductCard key={product.id} product={product} addToCart={handleAddToCart} />
                        ))}
                        {/* Invisible div to observe for triggering pagination */}
                        <div ref={listEndRef} className="w-full h-1"></div>
                    </div>
                ) : (
                    <div className="flex flex-col justify-center items-center">
                        <img src={noProductsImage} alt="No Products" className="max-h-40 mb-3" />
                        <p className="font-bold text-3xl mb-1">No Products To Display</p>
                        <p className="font-bold text-lg text-secondary mb-8">Check back later</p>
                    </div>
                )}
                {loading && (
                    <div className="flex justify-center py-4">
                        <LoadingSpinner />
                    </div>
                )}
            </div>
    );
};

export default ViewAllProducts;
