import axios from "axios";
import { shopAdv } from "../constants/apis";
import { getUserToken } from "../utils/loginUtils";

// Function to get slides
export const getSlides = async () => {
    const token = getUserToken();
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };
    const response = await axios.get(shopAdv, config);
    return response;
};

// Function to get shop Advs
export const getShopAdvs = async () => {
    const token = getUserToken();
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    };
    const response = await axios.get(shopAdv + "?is_advertisement=True", config);
    return response;
};
