import React from 'react';
import CustomLoadingCircularImage from './CircularImage';
import { Link } from 'react-router-dom';

const CategoryCard = ({ category }) => {
    return (
        <Link to={`/view/products?utm=category=${category.name}`} className="hover:text-gray-900">
            <div
                key={category.id}
                className="flex flex-col items-center cursor-pointer"
            >
                <div className="relative mb-2">
                    <div className="rounded-full overflow-hidden border-2 border-primary aspect-square">
                        <CustomLoadingCircularImage imageUrl={category.image || ""} size={"30rem"} />
                    </div>
                </div>
                <span className="text-sm md:text-[1rem] text-center font-semibold">{category.name}</span>
            </div>
        </Link>
    );
};

export default CategoryCard;
